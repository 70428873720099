<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      :background-color="sidebarBackground"
      short-title="DB Menos Administrador"
      title="DB Menos">
      <template slot="links">
        <sidebar-item v-if="!isFinamigo && !isMarketing && !isAuditor && !isGrowth && !isService && !isHumanResource" :link="{name: newMessage?'Chat (!)':'Chat', icon: newMessage?'fas fa-comment-medical text-success':'fas fa-comment-dots text-gray', path: '/chat'}"/>
        <sidebar-item v-if="isAdmin || isManagerService || isManagerSeller" :link="{name: 'Dev',icon: 'fas fa-code text-gray',path: '/devProjects'}"/>
        <sidebar-item v-if="!isFinamigo && !isMarketing && !isAuditor && !isGrowth && !isHumanResource" :link="{name: 'Dashboard',icon: 'ni ni-tv-2 text-gray',path: '/dashboard'}"/>
        <sidebar-item v-if="isAdmin || isManagerService || isManagerSeller" :link="{name: 'Actividades',icon: 'fas fa-tasks text-gray',path: '/activity'}"/>
        <sidebar-item v-if="isGrowth || isAdmin" :link="{name: isGrowth? 'Avance Proceso Autómatico':'Growth',icon: 'fas fa-chalkboard-teacher text-gray',path: '/processAutomaticGrowth'}"/>
        <sidebar-item v-if="!isService && !isFinamigo && !isMarketing && !isManagerService && !isGrowth && !isHumanResource" :link="{name: 'Leads', icon: 'fas fa-users text-gray', path: '/leads'}"/>
        <sidebar-item v-if="isAdmin || isManagerSeller" :link="{name: 'BucketLeads', icon: 'fas fa-users text-gray', path: '/bucketLeads'}"/>
        <sidebar-item v-if="!isFinamigo && !isMarketing && !isManagerService && !isGrowth && !isService && !isHumanResource" :link="{name: 'Proceso Automatico', icon: 'fas fa-history text-gray', path: '/history'}"/>
        <sidebar-item v-if="!isFinamigo && !isMarketing && !isGrowth && !isHumanResource" :link="{name: 'Clientes', icon: 'fas fa-user-friends text-gray', path: '/clients'}"/>
        <sidebar-item v-if="isAdmin || isAuditor || isManagerService || isManagerSeller" :link="{name: 'Nuevos clientes', icon: 'fas fa-user-plus text-gray', path: '/newUsers'}"/>
        <sidebar-item v-if="isAdmin || isManagerService" :link="{name: 'Nuevos clientes SC', icon: 'fas fa-address-book text-gray', path: '/newClients'}"/>
        <sidebar-item v-if="isAdmin || isManagerSeller || isManagerService || isAuditor || isSeller" :link="{name: isSeller? 'Mis Auditorías':'Auditoria Clientes', icon: 'fas fa-tasks text-gray', path: '/auditorUsersView'}"/>
        <sidebar-item v-if="isAdmin || isAuditor || isManagerService" :link="{name: 'Saldos Validados', icon: 'fas fa-calendar-check text-gray', path: '/viewAudit'}"/>
        <sidebar-item v-if="isAdmin || isAuditor || isManagerSeller" :link="{name: 'Depósitos', icon: 'fas fa-money-check-alt text-gray', path: '/payments'}"/>
        <sidebar-item v-if="isAdmin || isManagerSeller" :link="{name: 'Metas DBMenos', icon: 'fas fa-chart-line text-gray', path: '/projections'}"/>
        <sidebar-item v-if="isSeller || isService" :link="{name: 'Llamadas sin evento', icon: 'fas fa-users-cog text-gray', path: '/calls'}"/>
        <sidebar-item v-if="isAdmin || isManagerSeller" :link="{name: 'Pipeline', icon: 'fas fa-chart-area text-gray', path: '/pipeline'}"/>
        <sidebar-item v-if="isAdmin || isManagerService || isAuditor " :link="{name: 'Sol. Cuenta FA Temp.', icon: 'fas fa-address-book', path: '/contractsFA'}"/>
        <sidebar-item v-if="isAdmin" :link="{name: 'Contratos de Alto Riesgo', icon: 'fas fa-file-contract', path: '/contractsHighRisk'}"/>
        <sidebar-item v-if="isService" :link="{name: 'Mis Deudas', icon: 'fas fa-handshake text-gray', path: '/negotiations'}"/>
        <sidebar-item v-if="isAdmin || isManagerService || isAuditor" :link="{name: 'Liquidaciones del día', icon: 'fas fa-hand-holding-usd text-gray', path: '/settlementsDay'}"/>
        <sidebar-item v-if="isAdmin || isManagerService" :link="{name: 'Histórico de Addendums', icon: 'fas fa-file-invoice-dollar text-gray', path: '/addendumsHistory'}"/>
        <sidebar-item v-if="isAdmin" :link="{name: 'SPEI_IN', icon: 'fas fa-donate text-gray', path: '/speiIn'}"/>
        <sidebar-item v-if="isAdmin" :link="{name: 'Cobro de comisiones', icon: 'fas fa-hand-holding-usd', path: '/commissionCollect'}"/>
        <sidebar-item v-if="isAdmin" :link="{name: 'Comisiones cobradas', icon: 'fas fa-comments-dollar', path: '/commissionCharged'}"/>
         <!--<sidebar-item v-if="isAdmin" :link="{name: 'Cobro Comisiones', icon: 'fas fa-comments-dollar text-gray', path: '/commission'}"/>!-->
        <sidebar-item v-if="(isAdmin || isManagerService || isManagerSeller || isAuditor)" :link="{name: 'Reportes de crédito', icon: 'fas fa-file-invoice text-gray', path: '/creditReports'}"/>
        <sidebar-item v-if="(isAdmin || isManagerService || isManagerSeller)" :link="{name: 'Propuestas de ventas', icon: 'fas fa-file-alt text-gray', path: '/sellPropositions'}"/>
        <sidebar-item v-if="!isSeller && !isService && !isFinamigo && !isMarketing && !isManagerSeller && user.view_only === 0 && !isGrowth && !isHumanResource" :link="{name: 'Editor de cuentas', icon: 'fas fa-file-invoice-dollar text-gray', path: '/accountEditor'}"/>
        <!--<sidebar-item v-if="!isSeller && !isService && !isFinamigo && !isMarketing && !isManagerSeller && user.view_only === 0 && !isGrowth" :link="{name: 'Editor de comisiones', icon: 'fas fa-search-dollar text-gray', path: '/commissionEditor'}"/>!-->
        <sidebar-item v-if="(isAdmin || isFinamigo || isManagerService)" :link="{name: 'Finamigo', icon: 'far fa-grin text-gray', path: '/finamigoView'}"/>
        <sidebar-item v-if="!isSeller && !isService && !isFinamigo && !isMarketing && !isManagerSeller && user.view_only === 0 && !isGrowth && !isHumanResource" :link="{name: 'Saldos Finamigo', icon: 'far fa-credit-card text-gray', path: '/balanceVisor'}"/>
        <sidebar-item v-if="(isAdmin ||  isHumanResource) && !isManagerService && user.view_only === 0 " :link="{name: 'Consultores', icon: 'fas fa-headset text-gray', path: '/consultants'}"/>
        <sidebar-item v-if="isAdmin || isManagerService" :link="{name: 'Reportes Google',icon: 'fas fa-file-excel text-gray',path: '/sheetsReports'}"/>
        <sidebar-item v-if="(isAdmin || isManagerService) && user.view_only === 0" :link="{name: 'Cargas masivas', icon: 'fas fa-upload text-gray', path: '/massiveLoads'}"/>
        <sidebar-item v-if="(isAdmin || isMarketing) && user.view_only === 0" :link="{name: 'Blog', icon: 'fas fa-rss text-gray', path: '/blogEntries'}"/>
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar :isChat="isChat"></dashboard-navbar>

      <div @click="toggleSidebar">
        <fade-transition :duration="400" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
        <content-footer v-if="!$route.meta.hideFooter && !isChat"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
  import DashboardNavbar from './DashboardNavbar.vue';
  import ContentFooter from './ContentFooter.vue';
  import { FadeTransition } from 'vue2-transitions';

  export default {
    components: {
      DashboardNavbar,
      ContentFooter,
      FadeTransition
    },
    computed: {
      user() { return JSON.parse(localStorage.getItem(this.$v.STORE_KEYS.USER)) },
      newMessage() { return this.$store.state.socket.newMessage; }
    },
    data() {
      return {
        sidebarBackground: 'vue', //vue|blue|orange|green|red|primary
        isChat: this.$route.path === '/chat',
        isAdmin: false,
        isSeller: false,
        isService: false,
        isManagerSeller: false,
        isManagerService: false,
        isMarketing: false,
        isAuditor: false,
        isFinamigo: false,
        isGrowth:false,
        isHumanResource:false,
      };
    },
    created(){
      this.getRole()
    },
    watch: {
      $route(){
        this.isChat = this.$route.path === '/chat'
      }
    },
    methods: {
      toggleSidebar() {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false);
        }
      },
      getRole() {
        if (this.user){
          this.isAdmin = this.user.role === this.$v.ROLES.ADMIN
          this.isSeller = this.user.role === this.$v.ROLES.SELLER
          this.isService = this.user.role === this.$v.ROLES.SERVICE || this.user.role === this.$v.ROLES.AUDITOR
          this.isManagerService = this.user.role === this.$v.ROLES.MANAGER_SERVICE
          this.isManagerSeller = this.user.role === this.$v.ROLES.MANAGER_SELLER
          this.isMarketing = this.user.role === this.$v.ROLES.MARKETING
          this.isAuditor = this.user.role === this.$v.ROLES.AUDITOR
          this.isFinamigo = this.user.role === this.$v.ROLES.FINAMIGO
          this.isGrowth = this.user.role === this.$v.ROLES.GROWTH
          this.isHumanResource = this.user.role === this.$v.ROLES.HUMAN_RESOURCES
}else this.$router.push("/login")
      }
    }
  };
</script>
<style lang="scss">
</style>
