export let V = Object.freeze({
    //SERVER: "http://localhost:1025", // LOCAL
    //SERVER: "https://y0brxipd2d.execute-api.us-west-1.amazonaws.com/latest", // TEST
    SERVER: "https://8upvsokftb.execute-api.us-west-1.amazonaws.com/latest", // PROD
    CURRENT_VERSION: "1.48.30",
    CLIENT_SERVER: "https://dbmenos.com", // LOCAL
    CONSULTANT_IMG_PATH: "/img/consultants",
    SIGNATURE_PATH: "/signature",
    PAYMENT_PATH: '/updatePayment',
    STORE_KEYS: {
        GENERAL_INFO: "generalInfo",
        BANKS: "banks",
        SESSION: "session",
        USER: "user",
        SET_SHOW_LOADER: "setShowLoader"
    },
    OAUTH_GOOGLE_OPS :{
        GET_URL_GOOGLE:'/auth/getUrlGoogle',
        GET_AUTH_TOKEN:'/auth/success'
    },
    OPS: {
        SEND_NEGOTIATION_PUSH_NOTIFICATION: '/dbm/sendNegotiationPushNotification',
        INSERT: "/dbm/insert/",
        UPDATE: "/dbm/update/",
        SELECT: "/dbm/select/",
        DELETE: "/dbm/delete/",
        CALCULATE: "/dbm/calculate",
        LOGIN: "/usr/login",
        SIGNUP: "/usr/signup",
        LINK_ACCOUNT: "/usr/linkacc",
        UPLOAD_PAYMENT: "/dbm/uploadpayment",
        UPLOAD_FILE: "/dbm/uploadfile",
        GET_USER_FILE: "/dbm/getfile",
        VERIFY_SESSION: "/dbm/validatesess",
        VALIDATE_LEAD_SMS_CODE: "/dbm/validate_lead_sms_code",
        GET_MX_STATES: "/dbm/getstates",
        GET_TRACING_STATUS: "/dbm/getTracingStatus",
        GET_RFC_CURP: "/dbm/getrfccurp",
        GET_POSTAL_CODE: "/dbm/getpostalcode",
        GET_FINAMIGO_BALANCE: "/slr/getFinamigoBalance",
        GET_USER_FINAMIGO_FILES: "/slr/getUserFinamigoFiles"
    },
    ADMIN_OPS: {
        ASSIGN_MASSIVE_LEADS: '/adm/assignMassiveLeads',
        GET_SPEIN_IN_LOG:'/adm/getSpeinInLog',
        GET_USER_SIGNATURE:'/adm/getUserSignature',
        GET_MASSIVE_PAYMENTS: "/adm/getPayments",
        DELETE_ACCOUNT: "/adm/del/account",
        SET_CONSULTANT: "/adm/set_consultant",
        GET_BLOG_ENTRY: "/api/blogEntry",
        GET_BLOG_ENTRIES: "/api/blogEntries",
        GET_BLOG_WEBINAR: '/api/blogWebinar',
        GET_USER_HISTORY: "/adm/getUserHistory",
        GET_NEW_USER_LIST: "/adm/getNewUserList",
        UPLOAD_CSV: "/adm/uploadCSV",
        APPLY_COMMISSIONS_CSV: "/adm/applyCommissionsCSV",
        APPLY_NEGOTIATIONS_CSV: "/adm/applyNegotiationsCSV",
        APPLY_INCIDENCIAS_CSV: "/adm/applyIncidenciasCSV",
        GET_V_CONTACTS: "/adm/vContacts",
        GET_V_CHATS : "/adm/vChats",
        SET_USER_MONEY_BACK: '/adm/setUserMoneyBack',
        GET_USER_MONEY_BACK: '/adm/getUserMoneyBack',
        GET_MONEY_BACK_CATALOG: '/adm/getMoneyBackCatalog',
    },
    DEVS:{
        SET_PROJECTS:"/dev/addProjects",
        GET_PROJECTS:"/dev/getProjects",
        GET_VIEW_PROJECTS:"/dev/getViewProjects",
        SET_MODULES:"/dev/addModules",
        GET_MODULES:"/dev/getModules",
        SET_MODULES_HISTORY:"/dev/addModulesHistory",
        GET_MODULES_HISTORY:"/dev/getModulesHistory",
        GET_MODULES_EVENTS_HISTORY:"/dev/getModulesEventsHistory",
        UPLOAD_MODULE_DATE:"/dev/uploadModuleDate"
    },
    MANAGER_OPS: {
        GET_VIEW_NEW_CLIENTS_MANAGER:"/mgr/getViewNewClientsManager",
        SET_STATUS_ACCOUNT: "/mgr/setStatusAccount",
        SET_DELETE_HISTORY: "/mgr/setDeleteHistory",
        SET_DELETE_CREDIT_REPORT : "/mgr/setDeleteCreditReport",
        SET_SELLER_SETUP:"/mgr/setSellerSetup",
        GET_SELLER_SETUP:"/mgr/getSellerSetup",
        GET_C_SELLER_SETUP:"/mgr/getCSellerSetup",
        GET_VIEW_LEADS_SELLER_SETUP:"/mgr/getViewLeadsSellerSetup",
        AUTHORIZATION_UPLOAD_AUDIT:"/mgr/authorizationUploadAudit",
        GET_PIPELINE_TABLE:"/mgr/getPipelineTable"
    },
    SELLER_OPS: {
        GET_PROFILE_FA:'/slr/getProfileFA',
        GET_EXISTS_WELCOME: '/slr/getExistsWelcome',
        GET_PIN_FA: '/slr/getPinFA',
        RESEND_PIN_FA: '/slr/resendPinFA',
        GET_TOKEN_FA: '/slr/getTokenFA',
        GET_FILES_FINAMIGO: '/slr/getFilesFinamigo',
        SET_CONTRACT_DATA_USER: '/slr/setContractDataUser',
        GET_FULL_PROFILE_SELLER:'/srv/getFullProfileSeller',
        REQUEST_RE_SEND_CONTRACT_SIGNATURE:'/slr/requestReSendContractSignature',
        GET_CONSULTANTS_TABLE: '/slr/getConsultantsTable',
        GET_VIEW_CREATE_COMMISSION: '/slr/getViewCreateCommissions',
        GET_CALL_LOG_WITHOUT_TRACING:'/slr/getCallLogWithoutTracing',
        SET_EVENT_BY_ROLE:'/slr/setEventByRole',
        SEND_STARTING_CALL_PUSH_NOTIFICATION: '/slr/sendStartingCallPushNotification',
        SELLER_GET_SELL_TRACING_BOT: '/slr/getSellTracingBot',
        UPLOAD_FILE_S3_FINAMIGO:'/slr/uploadFileS3Fiamigo',
        REQUEST_SIGNATURE_LETTER_DBM:"/slr/requestSignatureLetterdbm",
        CREATE_ELECTRONIC_SIGNATURE:"/slr/createElectronicSignature",
        GET_PERCENTAGES_COMMISSIONS : '/slr/getPercentagesComissions',
        GET_PROGRAM_CALCULATION_ADD: '/slr/programCalculationAdd',
        GET_SUM_AMOUNTS_ACCOUNTS: '/slr/getSumAmountsAccounts',
        GET_COMMISSION_PERCENTAGE:'/slr/getCommissionPercentage',
        SET_PROGRAM_CALCULATOR_CR: '/slr/setProgramCalulatorCr',
        GENERATE_PROGRAM_CALCULATION:'/slr/generateProgramCalculation',
        SET_READ_FCM_CONSULTANTS: "/slr/setReadFcmConsultants",
        SET_FCM_TOKEN_CONSULTANTS :"/slr/setFcmTokenConsultants",
        GET_USER_ACCOUNTS: "/slr/getUserAccounts",
        GET_USER_PAYMENTS: "/slr/getUserPayments",
        GET_USER_COMMISSIONS: "/slr/getUserCommissions",
        GET_USER_FILES: "/slr/getUserFiles",
        GET_USER_FINAMIGO: "/slr/getUserFinamigo",
        SET_USER_FINAMIGO: "/slr/setUserFinamigo",
        //GET_USER_TRACING: "/slr/getUserTracing",
        GET_USER_ACCOUNT_TRACING: "/slr/getUserAccountTracing",
        CREATE_CALC_SELL_PROPOSITION: '/slr/createCalcSellProposition',
        CREATE_CALC_SELL_PROPOSITIONV2: '/slr/createCalcSellPropositionv2',
        SELLER_SET_SELL_TRACING: "/slr/setSellTracing",
        SELLER_GET_SELL_TRACING: "/slr/getSellTracing",
        SEND_MASSIVE_PUSH_NOTIFICATION: "/slr/sendMassivePushNotification",
        SEND_PUSH_NOTIFICATION: "/slr/sendPushNotification",
        GET_USER_HISTORY: "/slr/getUserHistory",
        GET_USER_HISTORY_EVENTS: "/slr/getUserHistoryEvents",
        CREATE_FINAMIGO_DOCUMENTS: '/slr/createFinamigoDocuments',
        UPDATE_CREDIT_REPORT: '/slr/updateCreditReport',
        SEND_MESSAGE: "/slr/sendSMS",
        VALIDATE_SESSION: "/dbm/validatesess",
        GET_CONSULTANTS: "/slr/getConsultants",
        LOGIN: "/slr/login",
        GET_GENERAL_INFO: "/slr/getGeneralInformation",
        GET_PROFILE: "/slr/getProfile",
        //GET_PROFILES: "/slr/getProfiles",
        GET_LEADS: "/slr/getLeads",
        ASSIGN_LEAD: "/slr/assignLead",
        SET_SELL_TRACING: "/slr/setSellTracing",
        GET_SELL_TRACING: "/slr/getSellTracing",
        UPDATE_USER: "/slr/updateUser",
        GET_USER_FILE: "/slr/getUserFile",
        GET_USER_PAYMENT_FILE: "/slr/getUserPaymentFile",
        UPDATE_PAYMENT: "/slr/updatePayment",
        CREATE_COMMISSION: "/slr/createCommission",
        UPDATE_COMMISSION: "/slr/updateCommission",
        UPDATE_BENEFICIARY: "/slr/updateBeneficiary",
        GET_BENEFICIARY: "/slr/getBeneficiary",
        UPLOAD_FILE: "/slr/uploadFile",
        UPLOAD_PAYMENT: "/slr/uploadPayment",
        GET_FUTURE_ACCOUNTS: "/slr/getFutureAccounts",
        UPDATE_ACCOUNTS: "/slr/updateAccounts",
        CREATE_CONTRACT: "/slr/createContract",
        REQUEST_CREDIT_REPORT: "/slr/requestCreditReport",
        REQUEST_CREDIT_REPORT_PIN: "/slr/requestCreditReportPin",
        VALIDATE_CREDIT_REPORT_PIN: "/slr/validateCreditReportPin",
        CREATE_SIGNATURE_TOKEN: "/slr/createSignatureToken",
        CREATE_FINAMIGO_ACCOUNT: "/slr/createFinamigoAccount",
        UPLOAD_FINAMIGO_DOCUMENTS: "/slr/uploadFinamigoDocuments",
        RESET_USER_PASSWORD: "/slr/resetUserPassword",
        //UPDATE_USER_PAYMENT_STATUS: "/slr/updatePaymentStatus",
        CREATE_DBM_CONTRACT: "/slr/createDBMContract",
        GET_PRESIGNED_PUT_OBJECT_URL: "/slr/getPresignedPutS3ObjectUrl",
        UPLOAD_FILE_FINAMIGO: "/slr/uploadFinamigoFiles",
        GET_PROGRAM_CALCULATION: "/slr/programCalculation",
        GET_USER_FROM_LEAD: "/slr/getUserFromLead",
        GET_WHATSAPP_CONTACTS: "/slr/getWhatsAppContacts",
        GET_WHATSAPP_CHAT: "/slr/getWhatsAppChats",
        REQUEST_CREDIT_REPORT_SIGNATURE: "/slr/requestCreditReportSignature",
        REQUEST_CONTRACT_SIGNATURE: "/slr/requestContractSignature",
        GET_LEAD_TRACING_DASH:'/slr/getLeadTracingDash',
        GET_STATUS_CANCEL_BY_BALANCE:'/slr/getStatusCancelByBalance',
    },
    SERVICE_OPS: {
        INIT_CHAT_NERDS: '/srv/initChatNerds',
        VERIFICATION_ADDRESS:'/srv/verificationAddress',
        UPDATE_PDF_CONTRACT_FA:'/srv/updatePDFContractFA',
        SERVICE_UPDATE_STATUS_CONTRACT_FA: '/srv/updateStatusContractFA',
        SEND_QUESTIONS_CONTRACT_FA:'/srv/sendQuestionsContractFA',
        GET_CONTRACTS_FA:'/srv/getContractsFA',
        SET_PAY_BANK:'/srv/setPayBank',
        GET_VIEW_MONEY_BACK_USERS:'/srv/getViewMoneyBackUsers',
        SET_MONEY_BACK_CLABE:'/srv/setMoneyBackCLABE',
        GET_FULL_PROFILE_SC:'/srv/getFullProfileSC',
        CREATE_HISTORY_COMMISSIONS_USER:'/srv/createHistoryUserCommission',
        SERVICE_UPDATE_PAYMENT: '/srv/updatePayment',
        DELETE_LETTER_SETTLEMENTS:'/srv/deleteLetterSettlements',
        GET_VIEW_ADDENDUMS_HISTORY: '/srv/getViewAddendumsHistory',
        GET_V_DASHBOARD_METHOD_PAY: '/srv/getVDashMethodPay',
        GET_V_DASHBOARD_VOBO: '/srv/getVDashVOBO',
        GET_V_DASHBOARD_SETTLEMENTS_DAY: '/srv/getVDashSettlementsDay',
        SET_DEBT_SETTLED_CHECKED: '/srv/setDebtSettled',
        GET_C_PENDINGS_SC: '/srv/getCPendingsSC',
        SET_PENDINGS_SC: '/srv/setPendingsSC',
        GET_V_PROFILE_SC: '/srv/getVProfileSC',
        CREATE_SETTLEMENT_LETTER_APPROACH: '/srv/createSettlementLetterApproach',
        GET_FINAMIGO_BANKS: '/srv/getFinamigoBanks',
        REACTIVE_USER: '/srv/reactiveUser',
        GET_INFO_REACTIVE_USER:'/srv/getInfoReactiveUser',
        GET_FILES_CONTRACTS_HIGH_RISK:'/srv/getFilesContractsHighRisk',
        SEND_QUESTIONS_CONTRACT_HIGH_RISK:'/srv/sendQuestionsRisk',
        SEND_PDF_CONTRACT_HIGH_RISK:'/srv/sendContractAltoPDF',
        RECEPTIONS_PHOTOS_CONTRACTS:'/srv/receptionsPhotosPDFS',
        RESEND_FILES_CONTRACTS_HIGH_RISK:'/srv/resendFilesContractHighRisk',
        SEND_INFORMATION_DIRECTION:'/srv/sendInformationDirection',
        RESEND_FILES_GUIDE_HIGH_RISK:'/srv/resendFilesGuideHighRisk',
        SEND_THANKS_CONTRACTS_HIGH_RISK:'/srv/finalThanksContract',
        GET_CONTRACTS_HIGH_RISK:'/srv/getContractsHighRisk',
        SEND_SPEI_OUT: '/srv/sendSpeiOut',
        GET_FINAMIGO_STATEMENT_ACCOUNT: '/srv/getFinamigoStatementAccount',
        SET_DEBTS_SETTLEMENTS_DAY: '/srv/setDebtsSettlementsDay',
        REQUEST_ACCOUNT_SIGN: "/srv/requestAccountSign",
        GET_DEBTS_INFO_SERVICE: '/srv/getDebtsInfoService',
        GET_C_TYPE_GOALS_SC:'/srv/getCTypeGoalsSc',
        GET_VIEW_DEBTS_ALL: '/srv/getViewDebtsAll',
        GET_SETTLEMENT_PROBABILITY: '/srv/getSettlementProbabiity',
        CREATE_ACCOUNT_STATEMENT:'/srv/creteAccountStatement',
        SET_CONFIRMATION_OF_FUNDS: '/srv/setConfirmationOfFunds',
        UPLOAD_DEBT_FILE: '/srv/uploadDebtFiles',
        SET_STATUS_CLIENT:'/srv/setStatusClient',
        SET_PROGRAM_CALCULATOR_ADDENDUM: '/srv/setProgramCalculatorAddendum',
        GET_SC_DEBT_AP_PROCEDURE: '/srv/getAfterPaymentProcedure',
        SET_ADDENDUMS_DETAIL:'/srv/setAddendumsDetail',
        ADD_NEW_ADDENDUM_APPROACH:'/srv/addNewAddendumApproach',
        REQUEST_ADDENDUM_SIGN: '/srv/requestAddendumSign',
        SET_ADDENDUMS_APPROACH:'/srv/setAddendumsApproach',
        GET_COMMISSION_PERCENTAGE_ADDENDUM:'/srv/getCommissionPercentageAddendum',
        GET_PRODUCT_TYPE:'/srv/getProductType',
        GET_STATUS_ADDENDUMS:'/srv/getStatusAddendums',
        GET_ADDENDUMS_APPROACH:'/srv/getAddendumsApproach',
        GET_ADDENDUMS_DETAIL:'/srv/getAddendumsDetail',
        GET_SC_DEBT_DOCUMENTS: '/srv/getDebtSCDocuments',
        SET_SC_DEBT_AP_PROCEDURE: '/srv/setAfterPaymentProcedure',
        GET_ADDENDUMS:'/srv/getAddendums',
        GET_TYPE_ADDENDUMS:'/srv/getTypeAddendums',
        GET_DISCOUNTS_BANK: '/srv/getDiscountsBank',
        GET_SC_DEBTS_VIEW: '/srv/getDebtSCView',
        GET_SC_DEBT_ACTIVITY: '/srv/getDebtSCActivity',
        SET_SC_DEBT_ACTIVITY: '/srv/setDebtSCActivity',
        GET_SC_DEBT_ACTIVITY_CATALOG: '/srv/getSCDebtActivityCatalog',
        GET_SERVICE_DEBT_FIRMS: '/srv/getSCDebtFirms',
        SET_SERVICE_DEBT_FIRMS: '/srv/setSCDebtFirms',
        GET_MASSIVE_PAYMENTS: "/srv/getPayments",
        GET_AUDIT_VIEW: '/srv/getAuditView',
        SET_EFFECTIVE_COMMISSION: "/srv/setEffectiveCommission",
        GET_CONTRACT: "/srv/getContract",
        GET_USER_MOVEMENTS: "/srv/getUserMovements",
        SET_PAID_ACCOUNT: "/srv/setPaidAccount",
        GET_MASSIVE_CREDIT_REPORT: "/slr/getCreditReports",
        GET_USER_TRACING: "/srv/getUserTracing",
        SET_USER_TRACING: "/srv/setUserTracing",
        GET_EDITABLE_ACCOUNTS: "/slr/getEditableAccounts",
        GET_MASSIVE_COMMISSIONS: "/slr/getMassiveCommissions",
        GET_MASSIVE_BALANCES: "/slr/getMassiveFinamigoAccounts",
        GET_CREDIT_REPORT: '/slr/getCreditReport',
        GET_SERVICE_EVENTS: "/srv/getServiceEvents",
        SET_PROGRAM_CALCULATION: "/slr/programCalculation",
        GET_NOMINAS_BANK:"/srv/getNominasBank",
        SET_COVID: "/srv/setCovid",
        SET_PAYROLL_SAME_BANK: '/srv/setPayrollSameBank',
        GET_TOKEN_ADDENDUM:'/srv/getTokenAddendum',
    },
    CONSULTANT_OPS: {
        GET_RELATIONSHIP_BENEFICIARY_CATALOG: '/cst/getRelationshipBeneficiary',
        GET_CLOSING_DATE: '/cst/getClosingDate',
        GET_VIEW_USER_AUDITOR:'/cst/getViewUserAuditor',
        SEND_AUDIT_ALERT_FOR_CHANGES:'/cst/sendAuditAlert',
        SET_AUDITOR_USERS: '/cst/setAuditorUsers',
        GET_VIEW_USERS_AUDITATION :'/cst/getViewUsersAuditation',
        GET_USER_VIEW_AUDITOR: '/cst/getUserViewAuditor',
        GET_USER_AUDITOR: '/cst/getUserAuditor',
        SEND_COMMENTS_AUDIT_SELLERS:'/cst/sendCommentsAuditSellers',
        SET_USER_AUDITOR: '/cst/setUserAuditor',
        GET_VIEW_AUDITOR: '/cst/getViewAuditor',
        GET_USER_AUDITOR_CATALOG: '/cst/getUserAuditorCatalog',
        GET_AUDIT_PENDING_VALIDATED: '/cst/getAuditPendingValidated',
        GET_SC_DEBT_ACCOUNTS: '/cst/getDebtSCAccounts',
        GET_LAST_CONSULTANT:'/cst/getLastConsultant',
        GET_PROMISE_COVID:'/cst/getPromiseCovid',
        GET_SELL_PROFILES: '/cst/getSellProfiles',
        GET_SC_PROFILES: '/cst/getSCProfiles',
        GET_CLIENTS_TO_MANAGER: '/cst/getClientsToManager',
        ASSIGN_USER_SERVICE: '/cst/assignUserToService',
        AUTO_ASSIGN_USER: '/cst/autoAssignUser',
        SET_USER_ACTION: '/cst/setUserAction',
        GET_USER_ACTIONS: '/cst/getUserAction',
        GET_INACTIVE_CATALOG: '/cst/getInactiveCatalog',
        SET_INACTIVE_USER: '/cst/setInactiveUser',
        GET_INACTIVE_USER: '/cst/getInactiveUser',
        GET_USER_ACTIONS_CATALOG: '/cst/getUserActionCatalog',
        GET_NEW_USER_LIST: "/cst/getNewUserList",
        GET_NEW_USER_LIST_POST: "/cst/getNewUserListPost",
        GET_PROJECTIONS: '/cst/getProjections',
        SET_PROJECTION: '/cst/setProjection',
        GET_DATA_CLIENT: '/cst/getDataClient'
    },
    FINAMIGO_OPS: {
        FINAMIGO_UPDATE_PAYMENT: '/fna/updatePayment',
        GET_USER_FILE: '/fna/getUserFiles',
        GET_USER_PAYMENT_FILE: '/fna/getUserPaymentFile',
        SET_FINAMIGO: '/fna/setFinamigo',
        GET_AUDIT_WRONG_REFERENCE_STP: '/fna/getAuditWrongReferenceSTP',
        GET_ACCOUNTS: '/fna/getAccounts',
        GET_FILE_URL: '/fna/getUserFile',
        SEND_PAYMENT_TOKEN:'/fna/sendPaymentToken'
    },
    MARKETING_OPS: {
        SET_BLOG_ENTRY: "/mrk/setBlogEntry",
        REMOVE_BLOG_ENTRY: "/mrk/RemoveBlogEntry",
        SET_BLOG_WEBINAR_ENTRY:'/mrk/setBlogWebinarEntry',
        REMOVE_BLOG_WEBINAR:'/mrk/RemoveBlogWebinar',
        GET_BLOG_ENTRIES: '/mrk/blogEntries',
        GET_BLOG_WEBINAR: '/mrk/blogWebinar',
    },
    STADISTICS_OPS: {
        GET_V_ACTIVE_ACCOUNTS_PER_BANK: "/slr/vActiveAccountsPerBank",
        GET_V_ACTIVE_ACCOUNTS_TOTAL: "/slr/vActiveAccountsTotal",
        GET_V_ACTIVE_CLIENTS: "/slr/vActiveClients",
        GET_V_ACTIVE_CLIENTS_GRAPHICS: "/slr/vActiveClientsGraphics",
        GET_V_AMOUNT_ENTERED: "/slr/vAmountEntered",
        GET_V_CIERRES: "/slr/vCierres",
        GET_V_CLIENTS_PER_STATE: "/slr/vClients_per_state",
        GET_V_COMMISSIONS: "/slr/vCommissions",
        GET_V_COMMISSIONS_GRAPHICS: "/slr/vCommissionsGraphics",
        GET_V_CONSULTANTS_INFO: "/slr/vConsultantsInfo",
        GET_V_CONTRACTS_INFO: "/slr/vContractsInfo",
        GET_V_GRADUATES: "/slr/vGraduates",
        GET_V_GRADUATION_DATE: "/slr/vGraduationDate",
        GET_V_HISTORY_EVENTS_PER_DEVICE: "/slr/VHistoryEventsPerDevice",
        GET_V_HISTORY_USER: "/slr/VHistoryUser",
        GET_V_USER_EVENT_TOTAL: "/slr/VUserEventTotal",
        GET_V_INACTIVE_BAJA:"/slr/vInactiveBaja",
        GET_v_LAST_PAYMENT: "/slr/vLastPayment",
        GET_V_LEVEL_COUNT: "/slr/vLevelCount",
        GET_V_MARKETING_ORIGIN: "/slr/vMarketingOrigin",
        GET_V_MONTH_INFO: "/slr/vMonthInfo",
        GET_V_NEW_CLIENTS_STATS: "/slr/vNewClientStats",
        GET_V_PAYMENTS_PER_ACTIVE_CLIENT: "/slr/vPaymentsPerActiveClient",
        GET_V_REGISTER_INFO: "/slr/vRegisterInfo",
        GET_V_SETTLEMENTS: "/slr/vSettlements",
        GET_V_TOTAL_GRADUATES: "/slr/vTotalGraduates",
        GET_V_LEADS_USERS_SELLER:"/slr/vLeadUserSeller",

    },
    GROWTH_OPS: {
        GET_PROCESS_AUTOMATIC: "/grw/getProcessAutomatic",
    },
    REPORTS_SHEETS: {
        UPDATE_COMMISSIONS: '/rts/upCommissions',
        UPDATE_FACTURE: "/rts/upFacture",
        UPDATE_NEW_CLIENTS: "/rts/upNewClients",
        UPDATE_BALANCE: "/rts/upBalance",
        UPDATE_AUTO_PAYMENTS: "/rts/upAutoPayments",
        UPDATE_DUE_PAYMENTS: "/rts/upDuePayments",
        CREATE_COMMISSIONS: "/rts/createCommissions",
        CREATE_REPORTS_CIERRE_SC: '/rts/createReportsCierreSC',
        CREATE_REPORTS_SPEI_LOG:'/rts/createReportsSpeiInLog',
        UPDATE_CREDIT_REPORTS: '/rts/createReportsCreditReports',
        UPDATE_SALES_REPORTS: '/rts/createReportsSales',
        UPDATE_CALLS_ADVISERS_REPORTS: '/rts/createCallsToAdvisers',
        UPDATE_ACTIVITIES_REPORTS: '/rts/createActivities',
        UPDATE_SERVICES_REPORTS: '/rts/createServices',
        UPDATE_ALL_DEBTS: '/rts/createAllDebts',
        UPDATE_DEPOSITS: '/rts/createDeposits',
        UPDATE_CUST_SERV_TASKS: '/rts/createCustServTasks',
        UPDATE_CLIENT_STATUS_UPD: '/rts/createClientStatusUpd',
        UPDATE_REGISTERED_IN_MATE: '/rts/createRegisteredInMate',
        UPDATE_ACQUISITION_CHANNELS: '/rts/createAcquisitionChannels',
    },
    TABLES: {
        ACCOUNTS: "accounts",
        ACCOUNTS_TRACE_STATUS: "account_trace_status",
        ACCOUNTS_TRACING: "account_tracing",
        BANKS: "banks",
        ECONOMIC_ACTIVITIES: "economic_activities",
        CLIENTS: "clients",
        DEFS: "defs",
        FINAMIGO: "finamigo",
        FUTURE_ACCOUNTS: "future_accounts",
        LEADS: "leads",
        LEADS_STATUS: "leads_status",
        USER_TRACE_STATUS: "user_trace_status",
        USER_TRACING: "user_tracing",
        MARKETING_LEADS: "marketing_leads",
        PAYMENTS: "payments",
        COMMISSIONS: "commissions",
        SESSIONS: "sessions",
        USER_FILES: "user_files",
        USERS: "users",
        RELATION_BENEFICIARY: 'relation_beneficiary',
    },
    VIEWS: {
        PROFILE: "profile"
    },
    TERMS_CONDITIONS: "https://s3-us-west-1.amazonaws.com/dbmenos/documentos/TERMINOS+Y+CONDICIONES+GRUPO+DB+MENOS+2018.pdf",
    PRIVACY_NOTICE: "https://s3-us-west-1.amazonaws.com/dbmenos/documentos/AVISO+DE+PRIVACIDAD+GRUPO+DB+MENOS+2018.pdf",
    FACEBOOK_PIXEL_ID: "175290776628728",
    API_SUCCESS: "success",
    API_ROWS: "rows",
    API_ROW: "row",
    API_MESSAGE: "message",
    API_REASON: "reason",
    SESSION: "session",
    USER: "user",
    ROUTES: {
        DASHBOARD: "/dashboard",
        LEADS: "/leads",
        CLIENTS: "/clients",
        CLIENT: "/client",
        FINAMIGO: "/finamigoView",
        MARKETING: "/blogEntries",
        AUDITORIA: "/clients",
        GROWTH: "/processAutomaticGrowth",
        HUMAN_RESOURCES: "/consultants"
    },
    ROLES: {
        ADMIN: "admin",
        MANAGER_SERVICE: "manager_service",
        MANAGER_SELLER: "manager_seller",
        USER: "user",
        SELLER: "seller",
        SERVICE: "service",
        FINAMIGO: "finamigo",
        MARKETING: "marketing",
        AUDITOR: "audit",
        GROWTH:"growth",
        HUMAN_RESOURCES:"human_resources"
    },
    MODIFICATIONS: {
        ACCOUNT: {id:1, name:'Cuenta'},
        DOCUMENT: {id:2, name:'Documento'},
        PAYMENT: {id:3, name:'Pago'},
        CONTRACT: {id:4, name:'Contrato'},
        SIGNATURE_CONTRACT: {id:5, name:'Firma Contrato'},
        SIGNATURE_SETTLEMENT: {id:6, name:'Firma Liquidación'},
        SIGNATURE_LETTER: {id:7, name:'Firma Carta Nómina'},
        SIGNATURE_ADDENDUM: {id:8, name:'Firma Addendum'},
        CALCULATOR: {id:9, name:'Calculadora'},
        CREDIT_REPORT: {id:10, name:'Reporte de Crédito'},
        COMMISSION: {id:11, name:'Comisión'},
    },
    DEPARTAMENT: {
        DEV: "dev",
    },
    OBJECTS: {
        USER: {
            id: null,
            name: null,
            lastname_1: null,
            lastname_2: null,
            birthday: null,
            email: null,
            phone: null,
            id_number: null,
            curp: null,
            rfc: null,
            addr_postal_code: null,
            addr_street: null,
            addr_extnum: null,
            addr_intnum: null,
            addr_sublocality: null,
            gender: null,
            state_of_birth: null,
            consultant: null,
            economic_activity: null,
            civil_status: null
        },
        CONTRACT: {
            user: null,
            filename: null,
            completed: null,
            payday_1: null,
            payday: null,
            program_duration: null,
            monthly_savings: null,
            program_status: null,
            signature: null
        },

    }
})