<template>
  <transition name="fade">
    <div class="overlay" v-if="showLoader">
      <breeding-rhombus-spinner
              :animation-duration="1000"
              :size="60"
              :color="'#3953D3'"/>
    </div>
  </transition>
</template>

<script>
  import { BreedingRhombusSpinner } from 'epic-spinners'
  export default {
    name: 'loading-overlay',
    components: {
      BreedingRhombusSpinner
    },
    computed: {
      showLoader() { return this.$store.state.showLoader }
    },
    data() {
      return {
      }
    },
    methods: {

    }
  }
</script>

<style scoped>
  .overlay {
    position: fixed;
    top: 0;
    z-index: 1000000;
    background-color: #FFFFFF;
    opacity: 0.8;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .overlay img {
    width: 100px;
  }
</style>