import Vue from 'vue'
import Router from 'vue-router'
import DashboardLayout from '@/layout/DashboardLayout'
import AuthLayout from '@/layout/AuthLayout'
import {V} from '@/V'

Vue.use(Router)

let router = new Router({
  mode: 'history',
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/',
      redirect: 'clients',
      component: DashboardLayout,
      children: [
        {
          path: '/dashboard',
          name: 'dashboard',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "demo" */ './views/Dashboard.vue'),
          meta: {
            requiresAuth: true
          },
          beforeEnter (to, from, next) {
            let user = JSON.parse(localStorage.getItem(V.STORE_KEYS.USER))
//            console.log(user)
            if (user.role === 'finamigo') next('/finamigoView')
            else if (user.role === 'marketing') next('/blogEntries')
            else if (user.role === 'audit') next('/clients')
            else next()
          }
        },
        {
          path: '/activity',
          name: 'Actividades',
          component: () => import(/* webpackChunkName: "demo" */ './views/Activity.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/sheetsReports',
          name: 'Reportes',
          component: () => import(/* webpackChunkName: "demo" */ './views/SheetsReports.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/devProjects',
          name: 'Dev',
          component: () => import(/* webpackChunkName: "demo" */ './views/DevProjects.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/speiIn',
          name: 'SpeiIn',
          component: () => import(/* webpackChunkName: "demo" */ './views/speiIn.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/devModule',
          name: 'Dev Module',
          component: () => import(/* webpackChunkName: "demo" */ './views/DevModule.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/viewAuditProfile',
          name: 'AuditProfileView',
          component: () => import(/* webpackChunkName: "demo" */ './views/ViewAudit.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/clients',
          name: 'clientes',
          component: () => import(/* webpackChunkName: "demo" */ './views/Clients.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/pipeline',
          name: 'Pipeline',
          component: () => import(/* webpackChunkName: "demo" */ './views/Pipeline.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/newUsers',
          name: 'Nuevos clientes',
          component: () => import(/* webpackChunkName: "demo" */ './views/NewUsers.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/newClients',
          name: 'Nuevos clientes dbm',
          component: () => import(/* webpackChunkName: "demo" */ './views/NewClientsManager.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/payments',
          name: 'Depósitos',
          component: () => import(/* webpackChunkName: "demo" */ './views/MassivePayments.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/creditReports',
          name: 'Reportes de crédito',
          component: () => import(/* webpackChunkName: "demo" */ './views/MassiveCreditReports.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/sellPropositions',
          name: 'Propuestas de ventas',
          component: () => import(/* webpackChunkName: "demo" */ './views/MassivePropositions.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/calls',
          name: 'LLamadas',
          component: () => import(/* webpackChunkName: "demo" */ './views/ConsultantsCallLog.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/projections',
          name: 'Metas DBMenos',
          component: () => import(/* webpackChunkName: "demo" */ './views/Projections.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/contractsHighRisk',
          name: 'Contratos de Alto Riesgo',
          component: () => import(/* webpackChunkName: "demo" */ './views/ContractHighRisk.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/contractsFA',
          name: 'Contratos Temporales FA',
          component: () => import(/* webpackChunkName: "demo" */ './views/ContractFA.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/addendumsHistory',
          name: 'Histórico de Addendums',
          component: () => import(/* webpackChunkName: "demo" */ './views/AddendumsHistory.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/auditPendingValidated',
          name: 'Validaciones Pendientes de Auditoria',
          component: () => import(/* webpackChunkName: "demo" */ './views/AuditPendingValidated.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/auditorUsersView',
          name: 'Auditoria usuarios',
          component: () => import(/* webpackChunkName: "demo" */ './views/AuditorUsersView.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/viewAudit',
          name: 'Saldos Validados',
          component: () => import(/* webpackChunkName: "demo" */ './views/AuditorPaymentsView.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/history',
          name: 'Historial',
          component: () => import(/* webpackChunkName: "demo" */ './views/UserHistory.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/negotiations',
          name: 'Negociaciones',
          component: () => import(/* webpackChunkName: "demo" */ './views/Negotiations.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/settlementsDay',
          name: 'Liquidaciones al día',
          component: () => import(/* webpackChunkName: "demo" */ './views/DebtsSettlementsDay.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/processAutomaticGrowth',
          name: 'Proceso Automatico',
          component: () => import(/* webpackChunkName: "demo" */ './views/ProcessAutomaticGrowth.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/accountEditor',
          name: 'Editor de cuentas',
          component: () => import(/* webpackChunkName: "demo" */ './views/MassiveAccountEditor.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/commissionEditor',
          name: 'Editor de comisiones',
          component: () => import(/* webpackChunkName: "demo" */ './views/MassiveCommissionEditor.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/commissionCollect',
          name: 'Cobro de Comisiones',
          component: () => import(/* webpackChunkName: "demo" */ './views/MassiveAutomaticCommissionsCollect.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/commissionCharged',
          name: 'Comisiones Cobradas',
          component: () => import(/* webpackChunkName: "demo" */ './views/MassiveAutomaticCommissionsCharged.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/finamigoView',
          name: 'Finamigo',
          component: () => import(/* webpackChunkName: "demo" */ './views/FinamigoView.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/balanceVisor',
          name: 'Visor de saldos finamigo',
          component: () => import(/* webpackChunkName: "demo" */ './views/MassiveBalanceVisor.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/massiveLoads',
          name: 'Cargas masivas',
          component: () => import(/* webpackChunkName: "demo" */ './views/MassiveLoads.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/blogEntry',
          name: 'Entrada de blog',
          component: () => import(/* webpackChunkName: "demo" */ './views/BlogEntry.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/blogWebinarEntry',
          name: 'Entrada de post Webinar',
          component: () => import(/* webpackChunkName: "demo" */ './views/BlogWebinars.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/blogEntries',
          name: 'Entradas del blog',
          component: () => import(/* webpackChunkName: "demo" */ './views/BlogEntries.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/consultants',
          name: 'Consultores DBMenos',
          component: () => import(/* webpackChunkName: "demo" */ './views/Consultants'),
          meta: {
            requiresAuth: true
          },
          beforeEnter (to, from, next) {
            let user = JSON.parse(localStorage.getItem(V.STORE_KEYS.USER))
//            console.log(user)
            if (user.role === 'admin' || user.role =='human_resources') {
              next()
            } else {
              next('/dashboard')
            }
          }
        },
        {
          path: '/client',
          name: 'cliente',
          component: () => import(/* webpackChunkName: "demo" */ './views/Client.vue'),
          props: true,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/clientService',
          name: 'clientService',
          component: () => import(/* webpackChunkName: "demo" */ './views/ClientService.vue'),
          props: true,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/clientSeller',
          name: 'clientSeller',
          component: () => import(/* webpackChunkName: "demo" */ './views/ClientSeller.vue'),
          props: true,
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/leads',
          name: 'leads',
          component: () => import(/* webpackChunkName: "demo" */ './views/Leads.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/auth',
          name: 'auth',
          component: () => import(/* webpackChunkName: "demo" */ './views/OAuth.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/bucketLeads',
          name: 'BucketLeads',
          component: () => import(/* webpackChunkName: "demo" */ './views/BucketLeads.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/icons',
          name: 'icons',
          component: () => import(/* webpackChunkName: "demo" */ './views/Icons.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/profile',
          name: 'profile',
          component: () => import(/* webpackChunkName: "demo" */ './views/UserProfile.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/calculator',
          name: 'calculadora',
          component: () => import(/* webpackChunkName: "demo" */ './views/Calculator.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/viewDebt',
          name: 'Vista Deudas',
          component: () => import(/* webpackChunkName: "demo" */ './views/ViewDebt.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/addendum',
          name: 'Addendum',
          component: () => import(/* webpackChunkName: "demo" */ './views/Addendums.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/maps',
          name: 'maps',
          component: () => import(/* webpackChunkName: "demo" */ './views/Maps.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/tables',
          name: 'tables',
          component: () => import(/* webpackChunkName: "demo" */ './views/Tables.vue'),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: '/chat',
          component: () => import(/* webpackChunkName: "demo" */ './views/Chat.vue'),
          meta: {
            requiresAuth: true
          }
        }
      ]
    },
    {
      path: '/',
      redirect: 'login',
      component: AuthLayout,
      children: [
        {
          path: '/login',
          name: 'login',
          component: () => import(/* webpackChunkName: "demo" */ './views/Login.vue'),
          meta: {
            requiresAuth: false
          }
        },
        {
          path: '/register',
          name: 'register',
          component: () => import(/* webpackChunkName: "demo" */ './views/Register.vue'),
          meta: {
            requiresAuth: false
          }
        }
      ]
    }
  ]
})

router.beforeEach((to, from, next) => {
  let session = localStorage.getItem("session")
  if (to.meta.requiresAuth && (session === undefined || session === null)) {
    next('/login')
  } else {
    next()
  }
})

export default router
