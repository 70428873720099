<template>
    <div id="app">
        <notifications></notifications>
        <main>
            <fade-transition origin="center" mode="out-in" :duration="250">
                <router-view/>
            </fade-transition>
            <loading-overlay></loading-overlay>
        </main>
    </div>
</template>

<script>
    import { FadeTransition } from "vue2-transitions";
    import LoadingOverlay from "./components/LoadingOverlay"

    export default {
        components: {
            FadeTransition,
            LoadingOverlay
        },
        mounted() {
          this.$store.dispatch('downloadUserTraceStatus')
          this.$store.dispatch('downloadUserTracingESCStatus')
          this.$store.dispatch('downloadAccountTraceStatus') //
          this.$store.dispatch('downloadBanks')
          this.$store.dispatch('downloadFinamigoBanks')
          this.$store.dispatch('downloadEconomicActivities')
          this.$store.dispatch('downloadRelationBeneficiary');
          this.$store.dispatch('downloadLeadStatus')
          this.$store.dispatch('downloadMxStates')
          this.$store.dispatch('downloadTracingStatus')
          this.$store.dispatch('downloadUserActionsCatalog')
          this.$store.dispatch('downloadCustomerService')
          this.$store.dispatch('downloadSellers')
          this.$store.dispatch('downloadTeamsLeaders')
          this.$store.dispatch('downloadUserInactiveCatalog')
          this.$store.dispatch('downloadPendingsSCCatalog')
          this.$store.dispatch('downloadMoneyBackCatalog')
          this.$store.dispatch('downloadAuditorCatalog')
          this.$store.dispatch('downloadSetupSellerCatalog')
          this.$store.dispatch('downloadClosingDate')
          this.$openSocket();
          if (getLS(this.$v.STORE_KEYS.SESSION)) {
              this.$axios.get(this.$v.SERVER + this.$v.SELLER_OPS.VALIDATE_SESSION).then(response => {
                  if (response.data.valid !== true) {
                      this.$router.push("/login")
                  }
              })
              if (this.$getLS(this.$v.STORE_KEYS.USER).role !== this.$v.ROLES.FINAMIGO && this.$getLS(this.$v.STORE_KEYS.USER).role !== this.$v.ROLES.MARKETING && this.$getLS(this.$v.STORE_KEYS.USER).role !== this.$v.ROLES.AUDITOR){
                  this.$store.dispatch('downloadGeneralInformation')
                  this.$store.dispatch('downloadUserHistoryEvents')
                  this.$store.dispatch('downloadUserInactiveCatalog')
                  this.$store.dispatch('downloadDebtActivityCatalog')
                  this.$store.dispatch('downloadDebtFirms')
                  this.$store.dispatch('downloadTypeAddendums')
                  this.$store.dispatch('downloadStatusAddendums')
                  this.$store.dispatch('downloadProductType')

              }
              if (this.$getLS(this.$v.STORE_KEYS.USER).role === this.$v.ROLES.ADMIN || this.$getLS(this.$v.STORE_KEYS.USER).role === this.$v.ROLES.HUMAN_RESOURCES || this.$getLS(this.$v.STORE_KEYS.USER).role === this.$v.ROLES.SERVICE || this.$getLS(this.$v.STORE_KEYS.USER).role === this.$v.ROLES.MANAGER_SERVICE || this.$getLS(this.$v.STORE_KEYS.USER).role === this.$v.ROLES.MANAGER_SELLER || this.$getLS(this.$v.STORE_KEYS.USER).role === this.$v.ROLES.AUDITOR) {
                  this.$store.dispatch('downloadAudits')
                  this.$store.dispatch('downloadAdmins')
                  this.$store.dispatch('downloadTypeGoalsSCCatalog')
                  this.$store.dispatch('downloadDevEventsHistory')
                  this.$store.dispatch('downloadTypeAddendums')
                  this.$store.dispatch('downloadStatusAddendums')
                  this.$store.dispatch('downloadProductType')
                  this.$store.dispatch('downloadUserInactiveCatalog')
                  this.$store.dispatch('downloadCommissionPercentages')
              }
          }
          console.log("----------------- VERSIÓN: " + this.$v.CURRENT_VERSION)
        },
    };
    function getLS(key) {
        let item = localStorage.getItem(key)
        if (item) {
            try {
                return JSON.parse(item)
            } catch (e) {
                return item
            }
        }
        else if (key === this.$v.STORE_KEYS.SESSION) {
            this.$router.push("/login")
        }
        return null
    }
</script>