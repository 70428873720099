import Vue from 'vue'
import Vuex from 'vuex'
import { V } from './V'

import axios from "axios";
/*const instance = axios.create({
  baseURL: V.SERVER
});*/

axios.interceptors.request.use(config => {
    let session = JSON.parse(localStorage.getItem(V.STORE_KEYS.SESSION))
    if (session) {
        config.headers.common[V.SESSION] = session[V.SESSION]
    }
    return config
}, error => {
    console.log(error)
})

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        axios: axios,
        showLoader: false,
        relationBeneficiary: [],
        banks: [],
        finamigoBanks: [],
        economicActivities: [],
        leadStatus: [],
        mxStates: [],
        generalInfo: {},
        activeAccountsPerBank: {},
        tracingStatus: [],
        userTraceStatus: [],
        userTracingESCStatus: [],
        accountTraceStatus: [],
        sellers: [],
        sellerNotEnable: [],
        TeamsLeaders: [],
        devs:[],
        customerService: [],
        customerServiceNotEnable: [],
        admins: [],
        audits: [],
        managerService: [],
        typeAddendums: [],
        statusAddendums: [],
        productType:[],
        CommissionPercentages:[],
        socket: {
            session: false,
            currentContact: {},
            newMessage: false,
            clientMessages: [],
            clientMessagesAdmin:[],
        },
        userHistoryEvents: [],
        devEventsHistory:[],
        userInactiveCatalog: [],
        debtActivityCatalog: [],
        typeGoalsSCCatalog: [],
        debtSCFirms: [],
        pendingsSC: [],
        moneyBackCatalog: [],
        setupSellerCatalog:[],
        auditorCatalog: {
            IF: [],
            IR: [],
            RFC: [],
            CD: [],
            RC: [],
            CURP: [],
            PROG: [],
            C1: [],
            C2: [],
            CA: [],
            HIGH: [],
        },
        closingDate: '',
    },
    mutations: {
        //socket
        setSocketSession(state, value) {state.socket.session = value},
        setClientMessages(state,value) {state.socket.clientMessages = value},
        setClientMessagesAdmin(state,value) {state.socket.clientMessagesAdmin = value},
        setCurrentContact(state, value){state.socket.currentContact = value},
        onNewMessage(state, value) {state.socket.newMessage = value},

        setBanks(state, value) { state.banks = value },
        setFinamigoBanks(state, value) { state.finamigoBanks = value },
        setEconomicActivities(state, value) { state.economicActivities = value },
        setRelationBeneficiary(state, value) { state.relationBeneficiary = value },
        setSellers(state, value) { state.sellers = value },
        setSellerNotEnable(state, value) { state.sellerNotEnable = value },
        setTeamsLeaders(state, value) { state.TeamsLeaders = value },
        setDevs(state, value) { state.devs = value },
        setCustomerService(state, value) { state.customerService = value },
        setCustomerServiceNotEnable(state, value) { state.customerServiceNotEnable = value },
        setAdmins(state, value) { state.admins = value },
        setAudits(state, value) { state.audits = value },
        setManagerService(state, value) { state.managerService = value },
        setLeadStatus(state, value) { state.leadStatus = value },
        setUserTracingESCStatus(state, value) { state.userTracingESCStatus = value },
        setUserTraceStatus(state, value) { state.userTraceStatus = value },
        setAccountTraceStatus(state, value) { state.accountTraceStatus = value },
        setMxStates(state, value) { state.mxStates = value },
        setGeneralInfo(state, value) { state.generalInfo = value },
        setTracingStatus(state, value) { state.tracingStatus = value },
        setShowLoader(state, value) { state.showLoader = value },
        setUserHistoryEvents(state, value) { state.userHistoryEvents = value },
        setTypeAddendums(state, value) { state.typeAddendums = value },
        setStatusAddendums(state, value) { state.statusAddendums = value },
        setProductType(state, value) { state.productType = value },
        setCommissionPercentages(state, value) { state.CommissionPercentages = value },
        setDevEventsHistory(state, value) { state.devEventsHistory = value },
        setUserActionsCatalog(state, value) { state.userActionsCatalog = value },
        setUserInactiveCatalog(state, value) { state.userInactiveCatalog = value },
        setDebtActivityCatalog(state, value) { state.debtActivityCatalog = value },
        setTypeGoalsSCCatalog(state, value) { state.typeGoalsSCCatalog = value },
        setDebtSCFirms(state, value) { state.debtSCFirms = value },
        setPendingsSCCatalog(state, value) { state.pendingsSC = value },
        setMoneyBackCatalog(state, value) { state.moneyBackCatalog = value },
        setSetupSellerCatalog(state, value) { state.setupSellerCatalog = value },
        setClosingDate(state, value) { state.closingDate = value },
        setAuditorCatalog(state, value) {
            for (let i=0; i<value.length; i++){
                switch (value[i].type){
                    case 'IF': state.auditorCatalog.IF.push(value[i]); break
                    case 'IR': state.auditorCatalog.IR.push(value[i]); break
                    case 'RFC': state.auditorCatalog.RFC.push(value[i]); break
                    case 'CD': state.auditorCatalog.CD.push(value[i]); break
                    case 'RC': state.auditorCatalog.RC.push(value[i]); break
                    case 'CURP': state.auditorCatalog.CURP.push(value[i]); break
                    case 'PROG': state.auditorCatalog.PROG.push(value[i]); break
                    case 'C1': state.auditorCatalog.C1.push(value[i]); break
                    case 'C2': state.auditorCatalog.C2.push(value[i]); break
                    case 'CA': state.auditorCatalog.CA.push(value[i]); break
                    case 'HIGH': state.auditorCatalog.HIGH.push(value[i]); break
                }
            }
        },
    },
    getters: {
        getBanks(state) { return state.banks },
        getFinamigoBanks(state) { return state.finamigoBanks },
        getEconomicActivities(state) { return state.economicActivities },
        getRelationBeneficiary(state) { return state.relationBeneficiary },
        getMxStates(state) { return state.mxStates },
        getGeneralInfo(state) { return  state.generalInfo },
        getTracingStatus(state) { return state.tracingStatus },
        getUserTraceStatus(state) { return state.userTraceStatus },
        getUserTracingESCStatus(state) { return state.userTracingESCStatus },
        getAccountTraceStatus(state) { return state.accountTraceStatus },
        getShowLoader(state) { return state.showLoader },
        getUserHistoryEvents(state) { return state.userHistoryEvents },
        getTypeAddendums(state) { return state.typeAddendums },
        getStatusAddendums(state) { return state.statusAddendums },
        getproductType(state) { return state.productType },
        getCommissionPercentages(state) { return state.CommissionPercentages },
        getUserActionsCatalog(state) { return state.userActionsCatalog },
        getDevEventsHistory(state) { return state.devEventsHistory },
        getUserInactiveCatalog(state) { return state.userInactiveCatalog },
        getDebtActivityCatalog(state) { return state.debtActivityCatalog },
        getTypeGoalsSCCatalog(state) { return state.typeGoalsSCCatalog }
    },
    actions: {
        showLoader({ commit }) {
            commit('setShowLoader', true)
        },
        hideLoader({ commit }) {
            commit('setShowLoader', false)
        },

        async downloadClientChat({ commit }){
            try {
                let url = V.SERVER + V.SELLER_OPS.GET_WHATSAPP_CHAT+'?offset=0&limit=50&phone='+this.state.socket.currentContact.phone
                axios.get(url).then(response => {
                    if (response.data[V.API_SUCCESS]) {
                        commit('setClientMessages', response.data[V.API_ROWS].reverse())
                    } else {console.log("error d_clientChat")}
                })
            } catch (e) {
                console.log(e)
            }
        },
        async downloadClientChatAdmin({ commit }){
            try {
                let url = V.SERVER + V.ADMIN_OPS.GET_V_CHATS+'?offset=0&limit=50&phone='+this.state.socket.currentContact.phone;

                axios.get(url).then(response => {
                    if (response.data[V.API_SUCCESS]) {
                        commit('setClientMessagesAdmin', response.data[V.API_ROWS])
                    } else {console.log("error d_clientChatAdmin")}
                })
            } catch (e) {
                console.log(e)
            }
        },
        async downloadBanks({ commit }) {
            let url = V.SERVER + V.OPS.SELECT + V.TABLES.BANKS
            axios.post(url).then(response => {
                if (response.data[V.API_SUCCESS]) {
                    commit('setBanks', response.data[V.API_ROWS])
                } else { console.log("error d_banks") }
            })
        },
        async downloadFinamigoBanks({ commit }) {
            let url = V.SERVER + V.SERVICE_OPS.GET_FINAMIGO_BANKS
            axios.get(url).then(response => {
                if (response.data[V.API_SUCCESS]) {
                    commit('setFinamigoBanks', response.data[V.API_ROWS])
                } else { console.log("error d_finamigoBanks") }
            })
        },
        async downloadEconomicActivities({ commit }) {
            let url = V.SERVER + V.OPS.SELECT + V.TABLES.ECONOMIC_ACTIVITIES
            axios.post(url).then(response => {
                if (response.data[V.API_SUCCESS]) {
                    commit('setEconomicActivities', response.data[V.API_ROWS])
                } else { console.log("error d_economicActivities") }
            })
        },
        async downloadRelationBeneficiary({ commit }) {
            let url = V.SERVER + V.OPS.SELECT + V.TABLES.RELATION_BENEFICIARY
            axios.post(url).then(response => {
                if (response.data[V.API_SUCCESS]) {
                    commit('setRelationBeneficiary', response.data[V.API_ROWS])
                } else { console.log("error d_relationBeneficiary") }
            })
        },
        async downloadSellers({ commit }) {
            let url = V.SERVER + V.SELLER_OPS.GET_CONSULTANTS + "?role=seller&enabled=1"
            axios.get(url).then(response => {
                if (response.data[V.API_SUCCESS]) {
                    commit('setSellers', response.data[V.API_ROWS])
                } else { console.log("error d_sellers") }
            })
            let url2 = V.SERVER + V.SELLER_OPS.GET_CONSULTANTS + "?role=seller"
            axios.get(url2).then(response => {
                if (response.data[V.API_SUCCESS]) {
                    commit('setSellerNotEnable', response.data[V.API_ROWS])
                } else { console.log("error d_sellerNotEnable") }
            })
        },
        async downloadTeamsLeaders({ commit }) {
            let url = V.SERVER + V.SELLER_OPS.GET_CONSULTANTS + "?role=manager_seller&enabled=1"
            axios.get(url).then(response => {
                if (response.data[V.API_SUCCESS]) {
                    commit('setTeamsLeaders', response.data[V.API_ROWS])
                } else { console.log("error d_TeamsLeaders") }
            })
        },
        async downloadDevs({ commit }) {
            let url = V.SERVER + V.SELLER_OPS.GET_CONSULTANTS + "?department=dev&enabled=1"
            axios.get(url).then(response => {
                if (response.data[V.API_SUCCESS]) {
                    commit('setDevs', response.data[V.API_ROWS])
                } else { console.log("error d_devs") }
            })
        },
        async downloadCustomerService({ commit }) {
            let service = "&role=service"
            let manager_service = "&role=manager_service"
            let url = V.SERVER + V.SELLER_OPS.GET_CONSULTANTS + "?enabled=1"
            let urlNotEnable = V.SERVER + V.SELLER_OPS.GET_CONSULTANTS + "?enabled=0"

            axios.get(url+service).then(response => {
                if (response.data[V.API_SUCCESS]) {
                    commit('setCustomerService', response.data[V.API_ROWS])
                } else { console.log("error d_CustomerService") }
            })
            axios.get(url+manager_service).then(response => {
                if (response.data[V.API_SUCCESS]) {
                    commit('setManagerService', response.data[V.API_ROWS])
                } else { console.log("error d_ManagerService") }
            })
            axios.get(urlNotEnable+service).then(response => {
                if (response.data[V.API_SUCCESS]) {
                    commit('setCustomerServiceNotEnable', response.data[V.API_ROWS])
                } else { console.log("error d_CustomerServiceNotEnable") }
            })
        },
        async downloadAdmins({ commit }) {
            let admin = "&role=admin"
            let manager_service = "&role=manager_service"
            let url = V.SERVER + V.SELLER_OPS.GET_CONSULTANTS + "?enabled=1"
            axios.get(url+admin).then(response => {
                if (response.data[V.API_SUCCESS]) {
                    commit('setAdmins', response.data[V.API_ROWS])
                } else { console.log("error d_Admins") }
            })
            axios.get(url+manager_service).then(response => {
                if (response.data[V.API_SUCCESS]) {
                    commit('setManagerService', response.data[V.API_ROWS])
                } else { console.log("error d_ManagerService") }
            })
        },
        async downloadAudits({ commit }) {
            let audit = "&role=audit"
            let url = V.SERVER + V.SELLER_OPS.GET_CONSULTANTS + "?enabled=1"
            axios.get(url+audit).then(response => {
                if (response.data[V.API_SUCCESS]) {
                    commit('setAudits', response.data[V.API_ROWS])
                } else { console.log("error d_Audits") }
            })
        },
        async downloadLeadStatus({ commit }) {
            let url = V.SERVER + V.OPS.SELECT + V.TABLES.LEADS_STATUS
            axios.post(url).then(response => {
                if (response.data[V.API_SUCCESS]) {
                    commit('setLeadStatus', response.data[V.API_ROWS])
                } else { console.log("error d_LeadStatus") }
            })
        },
        async downloadUserTracingESCStatus({ commit }) {
            let url = V.SERVER + V.OPS.SELECT + V.TABLES.USER_TRACE_STATUS
            axios.post(url).then(response => {
                if (response.data[V.API_SUCCESS]) {
                    commit('setUserTracingESCStatus', response.data[V.API_ROWS])
                } else { console.log("error d_UserTracingESCStatus") }
            })
        },
        async downloadUserTraceStatus({ commit }) {
            let url = V.SERVER + V.OPS.SELECT + V.TABLES.USER_TRACE_STATUS
            axios.post(url).then(response => {
                if (response.data[V.API_SUCCESS]) {
                    commit('setUserTraceStatus', response.data[V.API_ROWS])
                } else { console.log("error d_UserTraceStatus") }
            })
        },
        async downloadAccountTraceStatus({ commit }) {
            let url = V.SERVER + V.OPS.SELECT + V.TABLES.ACCOUNTS_TRACE_STATUS
            axios.post(url).then(response => {
                if (response.data[V.API_SUCCESS]) {
                    commit('setAccountTraceStatus', response.data[V.API_ROWS])
                } else { console.log("error d_AccountTraceStatus") }
            })
        },
        async downloadMxStates({ commit }) {
            let url = V.SERVER + V.OPS.GET_MX_STATES
            axios.get(url).then(response => {
                if (response.data[V.API_SUCCESS]) {
                    commit('setMxStates', response.data[V.API_ROWS])
                } else { console.log("error d_MxStates") }
            })
        },
        async downloadTracingStatus({ commit }) {
            let url = V.SERVER + V.OPS.SELECT + V.TABLES.LEADS_STATUS
            axios.post(url).then(response => {
                if (response.data[V.API_SUCCESS]) {
                    commit('setTracingStatus', response.data[V.API_ROWS])
                } else { console.log("error d_TracingStatus") }
            })
        },
        async downloadGeneralInformation({ commit }) {
            let url = V.SERVER + V.SELLER_OPS.GET_GENERAL_INFO
            return axios.get(url).then(response => {
                let gInfo = response.data
                if (gInfo[V.API_SUCCESS]) {
                    commit('setGeneralInfo', gInfo[V.API_ROW])
                } else { console.log("error d_GeneralInfo") }
            })
        },
        async downloadUserHistoryEvents({ commit }) {
            let url = V.SERVER + V.SELLER_OPS.GET_USER_HISTORY_EVENTS
            axios.get(url).then(response => {
                if (response.data[V.API_SUCCESS]) {
                    commit('setUserHistoryEvents', response.data[V.API_ROWS])
                } else { console.log("error d_userHistoryEvents") }
            })
        },
        async downloadTypeAddendums({ commit }) {
            let url = V.SERVER + V.SERVICE_OPS.GET_TYPE_ADDENDUMS
            axios.get(url).then(response => {
                if (response.data[V.API_SUCCESS]) {
                    commit('setTypeAddendums', response.data[V.API_ROWS])
                } else { console.log("error d_TypeAddendums") }
            })
        },
        async downloadStatusAddendums({ commit }) {
            let url = V.SERVER + V.SERVICE_OPS.GET_STATUS_ADDENDUMS
            axios.get(url).then(response => {
                if (response.data[V.API_SUCCESS]) {
                    commit('setStatusAddendums', response.data[V.API_ROWS])
                } else { console.log("error d_StatusAddendums") }
            })
        },
        async downloadProductType({ commit }) {
            let url = V.SERVER + V.SERVICE_OPS.GET_PRODUCT_TYPE
            axios.get(url).then(response => {
                if (response.data[V.API_SUCCESS]) {
                    commit('setProductType', response.data[V.API_ROWS])
                } else { console.log("error d_ProductType") }
            })
        },
        async downloadCommissionPercentages({ commit }) {
            let url = V.SERVER + V.SELLER_OPS.GET_PERCENTAGES_COMMISSIONS
            axios.get(url).then(response => {
                if (response.data[V.API_SUCCESS]) {
                    commit('setCommissionPercentages', response.data[V.API_ROWS])
                } else { console.log("error d_CommissionPercentages") }
            })
        },
        async downloadDevEventsHistory({ commit }) {
            let url = V.SERVER + V.DEVS.GET_MODULES_EVENTS_HISTORY
            axios.get(url).then(response => {
                if (response.data[V.API_SUCCESS]) {
                    commit('setDevEventsHistory', response.data[V.API_ROWS])
                } else { console.log("error d_DevEventsHistory") }
            })
        },
        async downloadUserActionsCatalog({ commit }) {
            let url = V.SERVER + V.CONSULTANT_OPS.GET_USER_ACTIONS_CATALOG
            axios.get(url).then(response => {
                if (response.data[V.API_SUCCESS]) {
                    commit('setUserActionsCatalog', response.data[V.API_ROWS])
                } else { console.log("error d_userActionsCatalog") }
            })
        },
        async downloadUserInactiveCatalog({ commit }) {
            let url = V.SERVER + V.CONSULTANT_OPS.GET_INACTIVE_CATALOG
            axios.get(url).then(response => {
                if (response.data[V.API_SUCCESS]) {
                    commit('setUserInactiveCatalog', response.data[V.API_ROWS])
                } else { console.log("error d_userInactiveCatalog") }
            })
        },
        async downloadDebtActivityCatalog({ commit }) {
            let url = V.SERVER + V.SERVICE_OPS.GET_SC_DEBT_ACTIVITY_CATALOG
            axios.get(url).then(response => {
                if (response.data[V.API_SUCCESS]) {
                    commit('setDebtActivityCatalog', response.data[V.API_ROWS])
                } else { console.log("error d_debtActivityCatalog") }
            })
        },
        async downloadDebtFirms({ commit }) {
            let url = V.SERVER + V.SERVICE_OPS.GET_SERVICE_DEBT_FIRMS
            axios.get(url).then(response => {
                if (response.data[V.API_SUCCESS]) {
                    commit('setDebtSCFirms', response.data[V.API_ROWS])
                } else { console.log("error d_debtSCFirms") }
            })
        },
        async downloadTypeGoalsSCCatalog({ commit }) {
            let url = V.SERVER + V.SERVICE_OPS.GET_C_TYPE_GOALS_SC
            axios.get(url).then(response => {
                if (response.data[V.API_SUCCESS]) {
                    commit('setTypeGoalsSCCatalog', response.data[V.API_ROWS])
                } else { console.log("error d_typeGoalsSCCatalog") }
            })
        },
        async downloadPendingsSCCatalog({ commit }) {
            let url = V.SERVER + V.SERVICE_OPS.GET_C_PENDINGS_SC
            axios.get(url).then(response => {
                if (response.data[V.API_SUCCESS]) {
                    commit('setPendingsSCCatalog', response.data[V.API_ROWS])
                } else { console.log("error d_pendingsSCCatalog") }
            })
        },
        async downloadMoneyBackCatalog({ commit }) {
            let url = V.SERVER + V.ADMIN_OPS.GET_MONEY_BACK_CATALOG
            axios.get(url).then(response => {
                if (response.data[V.API_SUCCESS]) {
                    commit('setMoneyBackCatalog', response.data[V.API_ROWS])
                } else { console.log("error d_moneyBackCatalog") }
            })
        },
        async downloadAuditorCatalog({ commit }) {
            let url = V.SERVER + V.CONSULTANT_OPS.GET_USER_AUDITOR_CATALOG
            axios.get(url).then(response => {
                if (response.data[V.API_SUCCESS]) {
                    commit('setAuditorCatalog', response.data[V.API_ROWS])
                } else { console.log("error d_auditorCatalog") }
            })
        },
        async downloadSetupSellerCatalog({ commit }) {
            let url = V.SERVER + V.MANAGER_OPS.GET_C_SELLER_SETUP
            axios.get(url).then(response => {
                if (response.data[V.API_SUCCESS]) {
                    commit('setSetupSellerCatalog', response.data[V.API_ROWS])
                } else { console.log("error d_SetupSellerCatalog") }
            })
        },
        async downloadClosingDate({ commit }) {
            let url = V.SERVER + V.CONSULTANT_OPS.GET_CLOSING_DATE
            axios.post(url).then(response => {
                if (response.data[V.API_SUCCESS]) {
                    commit('setClosingDate', response.data[V.API_ROWS])
                } else { console.log("error d_ClosingDate") }
            })
        },
    }
})

export default store