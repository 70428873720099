import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'
import ArgonDashboard from './plugins/argon-dashboard'

import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css' //Vuesax styles

import axios from 'axios'
import {V} from '@/V'
import es from 'vee-validate/dist/locale/es';
import numeral from 'numeral';
import BootstrapVue from 'bootstrap-vue';
import VeeValidate, {Validator} from 'vee-validate'
import moment from 'moment'
import Notification from 'vue-notification'
import VueClipboard from 'vue-clipboard2'
import ImageUploader from 'vue-image-upload-resize'
import underscore from 'vue-underscore'
import csvToJson from 'csvtojson';

import store from './store'
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/messaging';

try{
const firebaseConfig = {
  apiKey: "AIzaSyD4-nLhA-OWMCBABY2qDql00vn3kUIGHks",
  authDomain: "dbmenosflutter.firebaseapp.com",
  databaseURL: "https://dbmenosflutter.firebaseio.com",
  projectId: "dbmenosflutter",
  storageBucket: "dbmenosflutter.appspot.com",
  messagingSenderId: "AAAAcfuwNKA:APA91bGKdGaoBDNB4VVs3h-u0GYBHLoP_WnWCDuwaL0AZEUplQVKm5m6I5OuZDvMU25qC3iqnkYWKih74Aie9DAxiRA9W-oW1dGXYWME0nxknhbetwxezt96-Td2GXSmw-ZSMoanJbdx",
  appId: "1:489553933472:web:2262b7ebb876ee828d45c5",
  measurementId: "G-R8EEY4T1VP"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const messaging = firebase.messaging();
messaging.usePublicVapidKey(
  "BOztnAGtPF6_55_nsZ6Ai6E-6IthhXfiVq5k5UwdgxIxghMn4G65ZJce14XNuKxzCEPjfuTb44PS-hix64Y_sGE"
);

}catch(e){
  console.log(e);
}


/*const instance = axios.create({
  baseURL: V.SERVER
});*/

axios.interceptors.request.use(config => {
    let session = JSON.parse(localStorage.getItem(V.STORE_KEYS.SESSION))
    if (session) {
        config.headers.common[V.SESSION] = session[V.SESSION]
    }
    return config
}, error => {
    console.log(error)
})
Vue.use(Vuesax)
Vue.use(VeeValidate);
Vue.use(underscore)
Validator.localize('es', es);

Vue.config.productionTip = false
Vue.use(BootstrapVue);
Vue.use(ArgonDashboard)
Vue.use(VueClipboard)
Vue.use(ImageUploader);

moment.locale('es');
Vue.prototype.$csvToJson = csvToJson
Vue.prototype.$openSocket = openSocket
Vue.prototype.$axios = axios
Vue.prototype.$showLoader = showLoader
Vue.prototype.$moment = moment
Vue.prototype.$validateSession = validateSession
Vue.prototype.$formatDate = formatDate
Vue.prototype.$formatNormalDate = formatNormalDate
Vue.prototype.$formatDateHrs = formatDateHrs
Vue.prototype.$formatCurrency = formatCurrency
Vue.prototype.$formatCurrencyPorcentaje = formatCurrencyPorcentaje
Vue.prototype.$notification = notification
Vue.prototype.$getLS = getLS
Vue.prototype.$setLS = setLS
Vue.prototype.$v = V

Vue.use(Notification)

new Vue({
    router,
    render: h => h(App),
    store: store,
    async created() { },
    mounted: () => { },
    methods: {

    }
}).$mount('#app')

//Configuraciones Socket
function openSocket() {
    //console.log("Abriendo Socket...")
    let connection = new WebSocket("wss://82p2gxte75.execute-api.us-west-1.amazonaws.com/dev")
    Vue.prototype.$connection = connection
    connection.onopen = function(){
      //  console.log("Conexion exitosa")
        let {session} = getLS('session')
        if (session)
            connection.send(JSON.stringify({action: "setsession", session: session}))
    }
    connection.onmessage = function(event){
        messageReceived(JSON.parse(event.data))
    }
}

function messageReceived(json) {
    //console.log("Recibiendo: ", json);
    if (!json.action){
       // console.log("Mensaje enviado");
        store.dispatch('downloadClientChat');
        return
    }
    switch (json.action) {
        case 'validateconn':
            if (json.success) {
                return
            }else{
                console.log("Reconectar");//reconectar
            }
            break
        case 'login':
            if (json.success) {
                // Login Success
         //       console.log("Sesión iniciada")
                store.commit('setSocketSession', true)
                return
            }
            // Notificar al usuario que su sesión es inválida
            break
        case 'sendmessage':
           // console.log("Mensaje recibido");
            store.commit("onNewMessage", true);
            store.dispatch('downloadClientChat');
            break
    }
}

function setLS(key, json) {
    localStorage.setItem(key, JSON.stringify(json))
}

function getLS(key) {
    let item = localStorage.getItem(key)
    if (item) {
        try {
            return JSON.parse(item)
        } catch (e) {
            return item
        }
    }
    else if (key === V.STORE_KEYS.SESSION) {
        router.push("/login")
    }
    return null
}

function showLoader(show) {
    this.$store.commit('setShowLoader', show)
}

function validateSession(json) {
    if (!json['success']) {
        if (json['reason'] === 7) {
            // Session expired
            router.push("/login")
        }
    }
}

function formatDate(date) {
    if (!date) return '-'
    if(date <= '2020-11-10'){
        return moment(date).subtract(6,'hours').format("DD MMM, YYYY")
    }else{
        return moment(date).format("DD MMM, YYYY")
    }
}
function formatNormalDate(date) {
    if (!date) return '-'
    return moment(date).format("DD MMM, YYYY")
}
function formatDateHrs(date) {
    if (!date) return '-'
    return moment(date).format("DD MMM, YYYY HH:mm")
}
function formatCurrency(num) {
    if (!num) return '-'
    return numeral(num).format('$ 0,0.00')
}

function formatCurrencyPorcentaje(num) {
    if (!num) return '-'
    return numeral(num).format('0.0')
}

function notification(type='info', title='', text=''){
    if(!title && !text){
        switch (type){
            case 'success':
                title = 'Creado exitosamente!';
                text = '¡Gracias, se ha guardado correctamente el registro!';
                break;
            case 'danger':
                title = 'Error al guardar';
                text = 'Algo salió mal, por favor intenta nuevamente';
                break;
        }
    }
    switch (type){
        case 'success':
            return {color: 'success', icon: '<i class="fas fa-check-circle" ></i>', position: 'top-center', title, text}
        case 'warning':
            return {color: 'warning', icon: '<i class="fas fa-exclamation-triangle"></i>', position: 'top-center', title, text}
        case 'danger':
            return {color: 'danger', icon: '<i class="fas fa-exclamation-circle"></i>', position: 'top-center', title, text}
        case 'info':
            return {color: '#5bc0de', icon: '<i class="fas fa-info-circle"></i>', position: 'top-center', title, text}
    }
}