<template>
  <base-nav
    class="navbar-top navbar-dark"
    id="navbar-main"
    :show-toggle-button="false"
    :isChat="isChat"
    expand
  >
    <form :class="isChat ? formPosition : formPosition + ' ml-lg-auto'">
      <div class="form-group mb-0">
        <base-input
          v-if="!isGrowth"
          :placeholder="isChat ? 'Buscar conversación' : 'Buscar'"
          class="input-group-alternative"
          alternative
          v-model="searchQuery"
          v-on:keydown.enter.native="requestFind"
          addon-right-icon="fas fa-search"
        ></base-input>
      </div>
    </form>
    <ul v-if="!isChat" class="navbar-nav align-items-center d-none d-md-flex">
      <base-button
        v-if="isAdmin || isManagerSeller || isSeller || isManagerService"
        slot="title"
        type="primary"
        style="width: 100%"
        size="sm"
        @click="showModal()"
        >Buscar Cliente</base-button
      >
      <base-button
        v-if="isAdmin || isService || isManagerService"
        slot="title"
        type="warning"
        style="width: 100%"
        size="sm"
        @click="sendTokenLink()"
        >Dar Permisos MATI</base-button
      >
      <base-button
        slot="title"
        type="info"
        style="width: 100%"
        size="sm"
        v-if="!isGrowth"
        @click="createSignature()"
        >Mi Firma Electrónica <i class="fas fa-file-signature"></i
      ></base-button>
      <base-button
        slot="title"
        type="success"
        v-if="!isGrowth"
        class="text-white"
        style="width: 100%"
        size="sm"
        ><a
          href="https://dbmenos.s3-us-west-1.amazonaws.com/documentos/app-release.apk"
          class="font-weight-bold ml-1 text-white"
          target="_blank"
          >Descargar App Asesor</a
        ><i class="fab fa-android"></i
      ></base-button>
    </ul>
    <!---
        <base-input
          placeholder="Ingresa número telefonico"
          class="input-group-alternative"
          alternative
          v-model="searchQueryOther"
          v-on:keydown.enter.native="requestFind"
          addon-right-icon="fas fa-search"
        ></base-input>
    !-->
    <ul v-if="!isChat" class="navbar-nav align-items-center d-none d-md-flex">
      <li class="nav-item dropdown">
        <base-dropdown class="nav-link pr-0" v-bind:style="{ cursor: pointer }">
          <div class="media align-items-center" slot="title">
            <!--router-link to="/chat" class="icon icon-sm">
                            <i class="fas fa-comment text-white"></i>
            </router-link-->
            <span
              class="avatar avatar-sm rounded-circle"
              style="background-color: rgba(255, 255, 255, 0)"
            >
              <img
                alt="Image placeholder"
                src="img/brand/DB Menos logo 2020_Monograma original.png"
              />
            </span>
            <div class="media-body ml-2 d-none d-lg-block">
              <span class="mb-0 text-sm font-weight-bold"
                >{{ user.name }} {{ user.lastname_1 }}</span
              >
            </div>
          </div>

          <template>
            <!--<div class=" dropdown-header noti-title">
                            <h6 class="text-overflow m-0">Welcome!</h6>
                        </div>
                        <router-link to="/profile" class="dropdown-item">
                            <i class="ni ni-single-02"></i>
                            <span>My profile</span>
                        </router-link>
                        <router-link to="/profile" class="dropdown-item">
                            <i class="ni ni-settings-gear-65"></i>
                            <span>Settings</span>
                        </router-link>
                        <router-link to="/profile" class="dropdown-item">
                            <i class="ni ni-calendar-grid-58"></i>
                            <span>Activity</span>
                        </router-link>
                        <router-link to="/profile" class="dropdown-item">
                            <i class="ni ni-support-16"></i>
                            <span>Support</span>
                        </router-link>
            <div class="dropdown-divider"></div-->
            <a class="dropdown-item">
              <i class="fa fa-code-branch"></i>
              <span>Versión {{ $v.CURRENT_VERSION }}</span>
            </a>
            <a @click="logout" class="dropdown-item">
              <i class="ni ni-user-run"></i>
              <span>Cerrar sesión</span>
            </a>
          </template>
        </base-dropdown>
      </li>
    </ul>
    <modal
      :show.sync="client"
      v-on:close="client = false"
      v-bind:large="true"
      gradient="primary"
    >
      <div v-if="filter || !filter">
        <div class="col text-center">
          <b-form-group v-if=!filter>
            <b-form-checkbox-group
        v-model="selectedFind"
        :options="optionsFind"
        :state="state"
        buttons
        button-variant="info"
        size="lg"
        name="buttons-2"
      ></b-form-checkbox-group>
      <b-form-invalid-feedback :state="state" style="font-size:15pt; margin-top:30px">Se debe seleccionar solo una opción para buscar</b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div 
        v-if="selectedFind==1 && !filter" class="text-center">
          <base-input
          placeholder="Nombre"
          class="input-group-alternative"
          alternative
          v-model="searchQueryOther.name"
        ></base-input>
        <base-input
          placeholder="Primer Apellido"
          class="input-group-alternative"
          alternative
          v-model="searchQueryOther.lastname_1"
        ></base-input>
        <base-input
          placeholder="Segundo Apellido"
          class="input-group-alternative"
          alternative
          v-model="searchQueryOther.lastname_2"
        ></base-input>
        </div>
        <base-input
        v-if="selectedFind==2 && !filter"
          placeholder="Buscar Cliente (Ingresa no. de telefono)"
          class="input-group-alternative"
          alternative
          v-model="searchQueryOther.phone"
          v-on:keydown.enter.native="requestFindPhone"
          addon-right-icon="fas fa-search"
        ></base-input>
        <base-input
        v-if="selectedFind==3 && !filter"
          placeholder="Buscar Cliente (Ingresa email)"
          class="input-group-alternative"
          alternative
          v-model="searchQueryOther.email"
          v-on:keydown.enter.native="requestFindPhone"
          addon-right-icon="fas fa-search"
        ></base-input>

        <base-button
                slot="title"
                block
                type="info"
                size="lg"
                v-if="selectedFind==1 && !filter"
                style="margin-bottom:20px"
                @click="requestFindPhone()"
                addon-right-icon="fas fa-search"
                ><i class="fas fa-search"></i> Buscar</base-button
              >
        <div class="text-white font-italic" v-if="!filter">
          <base-alert type="danger" v-if="alert">
            <strong>{{ clientStatus }}</strong>
          </base-alert>
        </div>
      </div>
      <div class="row" v-if="!filter">
        <div class="col" style="margin-top: -2rem">
          <div class="card-profile-stats d-flex justify-content-center">
            <div class="text-center">
              <base-button
                slot="title"
                type="success"
                size="lg"
                v-if="!filter"
                @click="closeModal()"
                >Cerrar</base-button
              >
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="filter && searchQueryOther"
        class="container text-center text-white"
        style="padding: 1rem"
        v-on:click="clientSelected()"
      >
        <div class="row">
          <div class="col">
            <div class="card-profile-stats d-flex justify-content-center">
              <div class="card-profile-image">
                <a>
                  <img
                    src="img/theme/avatar.jpg"
                    class="rounded-circle"
                    style="background-color: aliceblue; margin-bottom: 5rem"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div
              class="card-profile-stats d-flex justify-content-center"
              style="margin-top: 5rem"
            >
              <div>
                <span class="display-3"
                  >{{ clientData.name }} {{ clientData.lastname_1 }}
                  {{ clientData.lastname_2 }}</span
                >
                <br />
                <span class="description text-white">Nombre</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="card-profile-stats d-flex justify-content-center">
              <div>
                <span class="heading">
                  <a>{{ clientData.id }}</a>
                </span>
                <span class="description text-white">ID</span>
              </div>
              <div>
                <span class="heading">{{ clientData.curp }}</span>
                <span class="description text-white">CURP</span>
              </div>
              <div>
                <span class="heading">{{ clientData.rfc }}</span>
                <span class="description text-white">RFC</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="card-profile-stats d-flex justify-content-center">
              <div>
                <span class="heading">{{
                  getConsultantName(clientData.consultant)
                }}</span>
                <span class="description text-white">Asesor DBM</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="card-profile-stats d-flex justify-content-center">
              <div class="text-center">
              <base-button
                slot="title"
                type="info"
                size="lg"
                v-if="filter"
                @click="otherFind()"
                >Otra Busqueda</base-button
              >
                <base-button
                  slot="title"
                  type="success"
                  v-if="filter"
                  size="lg"
                  @click="closeModal()"
                  >Cerrar</base-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </base-nav>
</template>
<script>
export default {
  props: {
    isChat: Boolean,
  },
  created() {
    this.isAdmin = this.user.role === this.$v.ROLES.ADMIN;
    this.isSeller = this.user.role === this.$v.ROLES.SELLER;
    this.isService = this.user.role === this.$v.ROLES.SERVICE;
    this.isManagerService = this.user.role === this.$v.ROLES.MANAGER_SERVICE;
    this.isAuditor = this.user.role === this.$v.ROLES.AUDITOR;
    this.isManagerSeller = this.user.role === this.$v.ROLES.MANAGER_SELLER;
    this.isGrowth = this.user.role === this.$v.ROLES.GROWTH;
    this.isDevs = this.user.department === this.$v.DEPARTAMENT.DEV;
  },
  computed: {
    state() {
        return this.selectedFind.length === 1
      },
    user() {
      return JSON.parse(localStorage.getItem(this.$v.STORE_KEYS.USER));
    },
    sellers() {
      return this.$store.state.sellers;
    },
    customerService() {
      return this.$store.state.customerService;
    },
  },
  data() {
    return {
      filter: false,
      alert: false,
      isDevs: false,
      isAdmin: false,
      isSeller: false,
      isService: false,
      isManagerSeller: false,
      isManagerService: false,
      isAuditor: false,
      activeNotifications: false,
      client: false,
      showMenu: false,
      isGrowth: false,
      searchQueryOther: {},
      searchQuery: "",
      clientData: {},
      clientStatus: "",
      pointer: "pointer",
      optionsFind: [
        { text: "Nombre(s) y Apellidos", value: 1 },
        { text: "Telefono", value: 2 },
        { text: "Correo Electronico", value: 3 },
      ],
      selectedFind: [],
      formPosition:
        "navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex",
    };
  },
  mounted() {},
  methods: {
    sendTokenLink() {
      let url = this.$v.SERVER + this.$v.OAUTH_GOOGLE_OPS.GET_URL_GOOGLE;
      this.$showLoader(true);
      this.$axios.get(url).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          let url = data[this.$v.API_ROW].response;
          window.open(url, "_blank");
        }
      });
    },
    showModal() {
      this.client = true;
    },
    createSignature() {
      let typeRol = "";
      if (this.user.role === "seller" && this.user.department === "seller") {
        typeRol = "Especialista en Eliminación de Deudas";
      } else if (
        this.user.role === "service" &&
        this.user.department === "service"
      ) {
        typeRol = "Asesor de Servicio al Cliente";
      } else if (
        this.user.role === "manager_service" &&
        this.user.department === "manager"
      ) {
        typeRol = "Gerente de Servicio al Cliente";
      } else if (
        (this.user.id === 31 || this.user.id === 30) &&
        this.user.role === "admin"
      ) {
        typeRol = "Gerente General";
      } else if (
        this.user.role === "manager_seller" &&
        this.user.department === "manager"
      ) {
        typeRol = "Gerente EED";
      } else if (
        (this.user.role === "audit" && this.user.department === "service") ||
        (this.user.role === "audit" && this.user.department === "admin")
      ) {
        typeRol = "Auditor";
      } else if (this.user.role === "marketing") {
        typeRol = "Marketing";
      } else if (
        this.user.role === "admin" &&
        this.user.department === "dev" &&
        this.user.id !== 1
      ) {
        typeRol = "Desarrollador";
      } else if (this.user.id === 1 && this.user.role === "admin") {
        typeRol = "CTO";
      } else if (this.user.id === 3 && this.user.role === "admin") {
        typeRol = "CFO";
      } else if (this.user.id === 2 && this.user.role === "admin") {
        typeRol = "CEO";
      } else if (this.user.role === "human_resources") {
        typeRol = "Recursos Humanos";
      } else {
        typeRol = "N/A";
      }
      let request = {
        id: this.user.id,
        name: this.user.name,
        lastname_1: this.user.lastname_1,
        lastname_2: this.user.lastname_2,
        email: this.user.email,
        phone: this.user.phone,
        role: typeRol,
        picture:
          "https://dbmenos.com/img/consultantFirm/" + this.user.pictureFirm,
      };
      let url = this.$v.SERVER + this.$v.SELLER_OPS.CREATE_ELECTRONIC_SIGNATURE;
      this.$axios.post(url, request).then((response) => {
        this.$showLoader(false);
        if (response.data.success) {
          let data = response.data;
          this.$vs.notification(
            this.$notification("success", "Firma electronica generada")
          );
          let url = data.row;
          window.open(url, "_blank");
        } else {
          this.$vs.notification(
            this.$notification("danger", "Algo salió mal", "Intenta nuevamente")
          );
        }
      });
    },
    otherFind() {
      this.searchQueryOther = {};
      this.filter = false;
      this.alert = false;
    },
    closeModal() {
      this.client = false;
      this.searchQueryOther = {};
      this.filter = false;
      this.alert = false;
    },
    logout() {
      localStorage.clear();
      this.$router.push("/login");
    },
    getConsultantName(id) {
      for (let i = 0; i < this.sellers.length; i++)
        if (this.sellers[i].id === id)
          return this.sellers[i].name + " " + this.sellers[i].lastname_1;
      for (let i = 0; i < this.customerService.length; i++)
        if (this.customerService[i].id === id)
          return (
            this.customerService[i].name +
            " " +
            this.customerService[i].lastname_1
          );
      return "- - -";
    },
    requestFind(e) {
      e.preventDefault();
      this.$root.$emit("searchQuery", this.searchQuery);
    },
    clientSelected() {
      window.open(
        "https://admin.dbmenos.com/client?id=" + this.clientData.id,
        "_blank"
      );
    },
    requestFindPhone() {
      let urlQuery="";
      if(this.searchQueryOther){
        console.log(this.searchQueryOther.phone)
        if(this.searchQueryOther.name){
          urlQuery+= "&name=" + this.searchQueryOther.name + "&lastname1=" + this.searchQueryOther.lastname_1 + "&lastname2=" + this.searchQueryOther.lastname_2;
        }else if(this.searchQueryOther.phone){
          urlQuery+= "&phone=" + this.searchQueryOther.phone;
        }else if(this.searchQueryOther.email){
          urlQuery+= "&email=" + this.searchQueryOther.email;
        }
      }
      let url =
        this.$v.SERVER +
        this.$v.CONSULTANT_OPS.GET_DATA_CLIENT +
        "?"+urlQuery;
      this.$showLoader(true);
      this.$axios.post(url).then((response) => {
        this.$showLoader(false);
        let data = response.data;
        if (data[this.$v.API_SUCCESS]) {
          let dataClient = data[this.$v.API_ROW];
          this.clientData = dataClient;
          this.filter = true;
          this.searchQueryOther = {}
        } else {
          switch (data[this.$v.API_REASON]) {
            case 1:
              this.clientStatus = "Usuario no encontrado";
              this.filter = false;
              this.alert = true;
              this.searchQueryOther = {}
              break;
            case 7:
              this.clientStatus = "Datos inválidos, intenta nuevamente";
              this.filter = false;
              this.alert = true;
              this.searchQueryOther = {}
              break;
            default:
              this.clientStatus = "Algo salió mal, intenta nuevamente";
              this.filter = false;
              this.alert = true;
              this.searchQueryOther = {}
              break;
          }
        }
      });
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
  },
};
</script>
