<template>
    <nav class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light bg-white" id="sidenav-main">
        <div class="container-fluid">

            <!--Toggler-->
            <navbar-toggle-button @click.native="showSidebar">
                <span class="navbar-toggler-icon"></span>
            </navbar-toggle-button>
            <router-link class="navbar-brand" to="/">
                <img :src="logo" class="navbar-brand-img" alt="...">
            </router-link>

            <slot name="mobile-right">
                <ul class="nav align-items-center d-md-none">
                    <!--<base-dropdown class="nav-item" position="right">
                        <a slot="title" class="nav-link nav-link-icon" href="#" role="button" data-toggle="dropdown"
                           aria-haspopup="true" aria-expanded="false">
                            <i class="ni ni-bell-55"></i>
                        </a>

                        <a class="dropdown-item" href="#">Action</a>
                        <a class="dropdown-item" href="#">Another action</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="#">Something else here</a>
                    </base-dropdown>-->
                    <base-dropdown class="nav-item" position="right">
                        <a slot="title" class="nav-link" role="button">
                            <div class="media align-items-center">
                              <span class="avatar avatar-md rounded-circle">
                                <img alt="Image placeholder" src="img/brand/DB Menos logo 2020_Monograma original.png" style="background-color: #2a3d9a">
                              </span>
                            </div>
                        </a>

                        <div class=" dropdown-header noti-title">
                            <h6 class="text-overflow m-0">{{user.name}} {{user.lastname_1}}</h6>
                        </div>
                        <div class="dropdown-divider"></div>
                        <a @click="logout" class="dropdown-item">
                            <i class="ni ni-user-run"></i>
                            <span>Cerrar sesión</span>
                        </a>
                    </base-dropdown>
                </ul>
            </slot>
            <slot></slot>
            <div v-show="$sidebar.showSidebar" class="navbar-collapse collapse show" id="sidenav-collapse-main">

                <div class="navbar-collapse-header d-md-none">
                    <div class="row">
                        <div class="col-6 collapse-brand">
                            <router-link to="/">
                                <img :src="logo">
                            </router-link>
                        </div>
                        <div class="col-6 collapse-close">
                            <navbar-toggle-button @click.native="closeSidebar"></navbar-toggle-button>
                        </div>
                    </div>
                </div>

                <ul class="navbar-nav">
                    <slot name="links">
                    </slot>
                </ul>
            </div>
        </div>
    </nav>
</template>
<script>
    import NavbarToggleButton from '@/components/NavbarToggleButton'

    export default {
        name: 'sidebar',
        components: {
            NavbarToggleButton
        },
        computed: {
            user() { return JSON.parse(localStorage.getItem(this.$v.STORE_KEYS.USER)) }
        },
        props: {
            logo: {
                type: String,
                default: 'img/brand/DB Menos logo 2020_Monograma azul.png',
                description: 'Sidebar app logo'
            },
            autoClose: {
                type: Boolean,
                default: true,
                description: 'Whether sidebar should autoclose on mobile when clicking an item'
            }
        },
        provide() {
            return {
                autoClose: this.autoClose
            };
        },
        methods: {
            logout() {
                localStorage.clear()
                this.$router.push("/login")
            },
            closeSidebar() {
                this.$sidebar.displaySidebar(false)
            },
            showSidebar() {
                this.$sidebar.displaySidebar(true)
            }
        },
        beforeDestroy() {
            if (this.$sidebar.showSidebar) {
                this.$sidebar.showSidebar = false;
            }
        }
    };
</script>
