<template>
  <div v-if="fileurl">
    <base-button class="btn-sm" type="primary" :disabled="pagination.currentPage===1" @click="pagination.currentPage--">
      <i class="fas fa-angle-left"></i>
    </base-button>
    Página {{pagination.currentPage}} de {{pagination.totalPages}}
    <base-button class="btn-sm" type="primary" :disabled="pagination.currentPage===pagination.totalPages" @click="pagination.currentPage++">
      <i class="fas fa-angle-right"></i>
    </base-button>
    <a :href="fileurl" target="_blank">
      <base-button class="btn-sm" type="info" title="Descargar">
        <i class="fas fa-download"></i>
      </base-button>
    </a>
    <pdf ref="pdf" style="border: 1px solid #2a3d9a;" :src="fileurl" :page="pagination.currentPage" @password="pass?pass:null" @num-pages="pagination.totalPages = $event" @link-clicked="pagination.currentPage = $event"></pdf>
  </div>
</template>
<script>
import pdf from 'vue-pdf'

export default {
  name: 'base-pdf-visor',
  components: {
    pdf: pdf,
  },
  props: {
    fileurl: String,
    pass: String
  },
  data () {
    return {
      pagination: {
        currentPage: 1,
        totalPages: 0,
      },
    }
  },
}
</script>